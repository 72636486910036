
import { objectLessAttributes } from '@aws-amplify/core';
import { API, Amplify} from 'aws-amplify';
//import fetch, { Headers } from 'node-fetch';
import axios from 'axios';

const apiName = 'default';

const paramsToQueryString = (params) =>{
    let q='';
    for(let p of Object.keys(params)){
        if(params[p]!=undefined){
            q+=`${p}=${params[p]}&`
        }
    }
    return q
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteDocuments = (params)=>{
   
    let restconf = { headers:{"Content-Type":"application/json"}, body:params };
    return API.post(apiName, `/app/documents/delete`, restconf);
    
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
const GetDocuments = (params)=>{
    /*
    params = {pagination:{page:1,perPage:10},sort:{field:'created_date',order:'DESC'},filter:{filename:'Service Provider',docsetname:'D6DEMO',status:'COMPLETE'}}
    */
    let qstring='?'
    if(params){
        for(let p of Object.keys(params)){
                switch(p){
                    case "pagination":
                        qstring += paramsToQueryString(params[p]);
                    break;
                    case "sort":
                        qstring += paramsToQueryString(params[p]);
                    break;
                    case "filter":
                        qstring += paramsToQueryString(params[p]);
                    break;
                }
        }
    }
    qstring = (qstring.length>1)?qstring.substring(0,qstring.length-1):'';
    return API.get(apiName, `/app/documents${qstring}`);
    
}

const CreateDocumentSet = async(params)=>{
    
    let restconf = { headers:{"Content-Type":"application/json"}, body:params };

    return API.post(apiName, '/app/documentsets',restconf);
    
}

const GetDocumentSets = async ()=>{
        return API.get(apiName, '/app/documentsets');
}

const GetDocumentUrl = async(docid)=>{
    let path = `/app/document/url/${docid}`
    return await API.get(apiName, path);
}
const GetDocumentObj = async(docid)=>{
    let path = `/app/document/obj/${docid}`
    return await API.get(apiName, path);
}
const MultipartFileUpload = async(params)=>{
    return new Promise(async (resolve,reject)=>{
        let a = 0;
        let errors = [];
        
        let rparam = {"data":{"attachments":[]}};
        for(let f of params.data.attachments){
            for(let ap of Object.keys(params.data))
            {
                if(ap!='attachments')
                {
                    rparam[ap]=params.data[ap];
                }
            }
            params.data.attachments[a].ContentType = f.rawFile.type;
            params.data.attachments[a].FileName = f.rawFile.name
            rparam.data.attachments.push(params.data.attachments[a]);
            a++;
        }    
        
        let restconf = { headers:{"Content-Type":"application/json"}, body:rparam };
        let res = await API.post(apiName, '/app/upload_init', restconf)
        if(res && res.documents){
            let fileuploads=[];
            for(let doc of res.documents){
                let axiosconfig = {
                       data: doc.src, 
                        headers: {
                            "Content-Type": doc.ContentType
                        }
                }
                let formData = new FormData();
                formData.append("Content-Type", doc.ContentType);
                Object.entries(doc.formData.fields).forEach(([key,value])=>{
                    formData.append(key,value);
                })
                //append relevant file
                formData.append('file',params.data.attachments.filter(f=>f.FileName==doc.FileName)[0].rawFile,doc.FileName);
                try{
                    let fupresult = await axios.post(
                        `${doc.formData.url}/`,
                        formData, 
                        { 
                            headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    });
                    //after successful result, call endpoint for upload complete operations
                    let res = await API.post(apiName, '/app/upload_complete', { headers:{"Content-Type":"application/json"}, body: {docids:[doc.docid]} })
                }catch(err){
                    console.error(err);
                    errors.push(err);
                }
            }

        }
        
        if(errors.length>0){
            reject(errors);
        }else{
            resolve({ data : {"id":1}});
        }
    });
}
const FileUpload = async(params)=>{
    
    
    return new Promise(async (resolve,reject)=>{
        let a = 0;
        let errors = [];
        
        for(let f of params.data.attachments){
            
            let rparam = {"data":{"attachments":[]}};
            for(let ap of Object.keys(params.data))
            {
                if(ap!='attachments')
                {
                    rparam[ap]=params.data[ap];
                }
            }
            
            
            params.data.attachments[a].data = await convertFileToBase64(f);
            delete params.data.attachments[a].rawFile
            delete params.data.attachments[a].src

            
            rparam.data.attachments.push(params.data.attachments[a]);
            

            let restconf = { headers:{"Content-Type":"application/json"}, body:rparam };
            try{
                let res = await API.post(apiName, '/app/uploadpolicy', restconf)
            }catch(err){
                console.error(err);
                errors.push(err);
            }

            a++;
        }
        
        if(errors.length>0){
            reject(errors);
        }else{
            resolve({ data : {"id":1}});
        }
    });

}


const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve({
        fileName: file.title,
        base64: reader.result
    });
    reader.onerror = reject;
});

const convertFileToBlob = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});




export { MultipartFileUpload, FileUpload, GetDocuments, GetDocumentSets, CreateDocumentSet, GetDocumentUrl, GetDocumentObj, DeleteDocuments}