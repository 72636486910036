import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext,
    Button

  } from "react";
  import {
    TextInput,
    ImageInput,
    ImageField,
    minLength,
    SimpleForm,
    required,
    useDataProvider,
    useNotify,
    SaveContextProvider,
    useGetIdentity,
  } from "react-admin";

  import { useFormContext, useFormState, useForm } from 'react-hook-form';
  import { SaveButton, Toolbar } from 'react-admin';

  import SaveIcon from '@mui/icons-material/Save';


  const ChangePasswordToolbar = ({
    customSaveButton,
    handleSubmitWithRedirect,
    onSubmit,
    record,
    resource,
    saveButton,
    saving,
    translate,
    invalid,
    label,
    props
  }) => {
    const formState = useFormState();
    const form = useForm();
  
    const handleCustomSubmit = useCallback(() => {
        if(formState.isValid){
            onSubmit();
        }
      }, [formState.defaultValues,formState.isValid]);
  
    const isNilOrEmpty = (record)=>{
        return (!record||undefined);
    }

    return (
        <Toolbar>
            <SaveButton
              id="btnchangepassword"
              label={label||"Save"}
              resource={resource}
              saving={saving}
              onClick={handleCustomSubmit}
              variant="text"
              disabled={(saving)}
            />
        </Toolbar>
    );
  };

export default ChangePasswordToolbar;