import { React,useEffect,useState, useMemo } from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    useCreate,
    useGetList,
    useCreateSuggestionContext,
    required
} from 'react-admin';
import { useForm } from "react-hook-form";


import {
    Box,
    BoxProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';


import { GetDocumentSets, GetDocuments } from '../dataproviders/documents'
/*
const choices = [
    { id: 'tech', name: 'Tech' },
    { id: 'lifestyle', name: 'Lifestyle' },
    { id: 'people', name: 'People' },
]
*/

const DocumentsCombo = (props) =>{
    const [create] = useCreate();
    const [ddldocdata, setDdlDocData]= useState();
    const { register } = useForm();
    const [selecteddocset,setSelectedDocSet]=useState(props.selecteddocset);


    useEffect(()=>{
         /*if(!ddldocdata){
             let docset = GetDocuments()
                .then((data)=>{
                    if(props.selectedDocSet){
                        console.log(`filter documents by selected docsetid ${props.selectedDocSet}`)
                        data.data = data.data.filter((f)=>f.docsetid==props.selectedDocSet)
                        console.log(data.data);
                        setDdlDocData(data.data);    
                    }
                })
                .catch((err)=>{
                    console.log('error calling GetDocuments()');
                    console.log(err);
                })
        }*/
    },[props.selecteddocset]);


    
        
    

    return (
        <ReferenceInput label="Documents" source="docid" reference="documents" optionText="filename" optionValue="docid" suggestionLimit={props.suggestionLimit || 1000} filter={{docsetid:props.selecteddocset,status:props.status}}>
            <AutocompleteInput
                {...props} 
                optionText='filename'
                label="Document"
                value="-1"
                />
        </ReferenceInput>
    )
}

const DocumentSetCombo = (props) =>{
    //create={((props.allowCreate==true)?<CreateDocumentSet />:null)} 
    const [create] = useCreate();
    const [ddldata, setDdlData] = useState();
    const { register } = useForm();

    const onCreate = (docsetname) => {
        return new Promise((resolve,reject)=>{
            create(
                'docset',
                { data: { docsetname: docsetname } },
                {
                    onSuccess: (data) => {
                        let currentset = ddldata;
                        setDdlData(currentset.concat(data));
                        resolve(data);
                    },
                }
            );
        })
      
    }
    
        useEffect(()=>{
            if(!ddldata){
             let docset = GetDocumentSets()
                .then((data)=>{
                    setDdlData(data.data);
                })
                .catch((err)=>{
                    console.log('error calling GetDocumentSets()');
                    console.log(err);
                })
            }
        },[]);
    

    
    return (

        <ReferenceInput label="Document Set" source="docsetid" reference="documentset" optionText="docsetname" optionValue="id" suggestionLimit={props.suggestionLimit || 1000} filter={{'status':props.status}}>
                <AutocompleteInput
                {...props} 
                source={props.source || 'docsetid'}
                onCreate={ (props.allowcreate=='true')?onCreate:undefined }
                optionText='docsetname'
                optionValue='id'
                label="Document Set"
                className={props.className}
                />        
        </ReferenceInput>
    )
}

export { DocumentSetCombo, DocumentsCombo}