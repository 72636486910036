import { React,useEffect,useState, useMemo } from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    useCreate,
    useGetList,
    useCreateSuggestionContext,
    required
} from 'react-admin';
import { useForm } from "react-hook-form";



const DataPointsCombo = (props) =>{
    

    return (
        <ReferenceInput {...props} label="Data Point" source="id" reference="framework" optionText="datapoint" optionValue="id" suggestionLimit={props.suggestionLimit || 1000} filter={{framework:props.framework}} >
            <AutocompleteInput
                {...props} 
                optionText='datapoint'
                label="Data Point"
                value="-1"
                />
        </ReferenceInput>
    )
}

export {DataPointsCombo}