import React from "react";
import { Layout } from "react-admin";
import SiteAppBar from "./appbar";
import { ProfileProvider } from "../pages/myprofile";

const MyLayout = (props) => (
  <ProfileProvider>
    <Layout {...props} appBar={SiteAppBar} />
  </ProfileProvider>
);

export default MyLayout;
