import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
  } from "react";
  import {
    TextInput,
    ImageInput,
    ImageField,
    SimpleForm,
    required,
    useDataProvider,
    useNotify,
    SaveContextProvider,
    useGetIdentity,
    List,
    Title
  } from "react-admin";
  
  import axios from 'axios';

import { useSearchParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
  
import { useParams } from 'react-router';
import { GetDocumentUrl, GetDocumentObj } from "../dataproviders/documents";

import {isMobile} from 'react-device-detect';

export const DocViewer = ({...props})=>{
    const { docid } = useParams();
    const [ docurl, setDocUrl ]=useState();
    const [ doctitle, setDocTitle ]=useState();
    let loadcount=0;

    useEffect(()=>{

        
        const loaddoc = async(docid)=>{
            if(docid){
                try{
                    
                    let doc = await GetDocumentObj(docid);
                    let rsp = await axios.get(doc.url, {responseType: 'blob'});
                    //const buff = Buffer.from(rsp.data);
                    //const pdf = new Blob([buff], { type: 'application/pdf' });
                    const targeturl = URL.createObjectURL(rsp.data);
                    //const buff = Buffer.from(doc.body, "base64");
                    //const pdf = new Blob([buff], { type: 'application/pdf' });
                    //const targeturl = URL.createObjectURL(pdf);
                    //const targeturl = URL.createObjectURL(rsp.data);
                    setDocUrl(targeturl);
                    setDocTitle(doc.filename);
                    
                    /*
                    if(isMobile){
                        //mobile devices shoudl not end up here, but if they do we will present them with a download link rather than an IFRAME
                        const a = document.createElement('a');
                        a.download = doc.filename;
                        a.href = targeturl;
                        a.addEventListener('click', (e) => {
                            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                        });
                        a.click();
                    }
                    */

                }catch(err){
                    console.error(err);
                }
            }
        }
        
        if(loadcount==0){
            loadcount++;
            loaddoc(docid);
        }
        

    },[]);

   
    
    return (
        (docid && docurl && docurl.length>0)
            ?(isMobile)
                ?<a href={docurl}>Download File:  {doctitle}</a>
                :<iframe src={docurl} width="100%" height="100%" style={{border:"0px"}} />
            :<LinearProgress />
    );


  }
