import { API, Amplify} from 'aws-amplify';


const apiName = 'default';


const GetPortalSession = async()=>{
    return API.get(apiName, '/app/portalsession');        
}


const GetPortalProvider = async()=>{
    return API.get(apiName, '/app/portalprovider');
}

export { GetPortalSession, GetPortalProvider }
