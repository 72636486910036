import React from "react";
import { forwardRef } from 'react';
import { UserMenu, MenuItemLink, useLogout  } from "react-admin";
import { useProfile } from "../pages/myprofile";


import SettingsIcon from '@mui/icons-material/Settings';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

import { MenuItem } from '@mui/material';

const LogoutButton = forwardRef((props,ref)=>{
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItemLink
            onClick={handleClick}
            primaryText="Logout"
            leftIcon={<ExitIcon />}
        />
    );
})

const MyUserMenu = (props) => {
  const { profileVersion } = useProfile();
  const logout = useLogout();
  const handleClick = () => logout();

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/preferences/user"
        primaryText="User Settings"
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        to="/usage"
        primaryText="Usage"
        leftIcon={<DataUsageIcon />}
      />
      {/*<MenuItemLink
        to="/my-profile"
        primaryText="Manage Subscriptions"
        leftIcon={<CardMembershipIcon />}
        />*/}
      <MenuItemLink
        to="/"
        onClick={handleClick}
        primaryText="Logout"
        leftIcon={<ExitIcon />}
      />
      
    </UserMenu>
  );
};

export default MyUserMenu;