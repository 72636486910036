import React, {useState, useEffect} from "react";
import {TextInput,minLength,SimpleForm,required,useNotify, maxLength, Placeholder, Toolbar} from "react-admin";
import { Auth } from 'aws-amplify';

import Link from '@mui/material/Link';


import { GetPortalSession, GetPortalProvider } from '../dataproviders/userinfo'



const SubscriptionDetail = (props)=>{
    const [currentuser, setCurrentUser] = useState();
    const [portalprovider, setPortalProvider] = useState();
    const [windowhandle, setWindowHandle ] = useState();

    useEffect(()=>{
                
        if(props.CurrentUser){
            setCurrentUser(props.CurrentUser);
            GetPortalProvider().then((data)=>{
                setPortalProvider(data.provider);
            })
            .catch((err)=>{
                console.error('error getting portal provider.')
                console.error(err);
            })

        }
        

    },[]);

       
    const closeWindow=()=>{
        windowhandle.close();

    }
  
    const openSession=(e)=>{
        e.preventDefault();
        GetPortalSession().then((data)=>{
            let portalwindow = window.open(data.access_url,"subscriptionsportal",'location=no,menubar=no,resizable=no,status=no,titlebar=no,toolbar=no');
            setWindowHandle(portalwindow);
            //document.location=data.access_url;
        })
        .catch((err)=>{
            console.error(err);
        });
    }
     

    return (currentuser && portalprovider)
    ?(portalprovider=='stripe')?
        <div>
            <div>Subscriptions are managed using Stripe services.</div>
            <div><Link variant="text" onClick={openSession} href="#">Click here</Link> to open the Stripe Subscription Management portal.</div>
        </div>
        :<div>Subscriptions are managed using another provider.  Get in touch with us!</div>
    :<div>
        You have no active subscription.  You must be participating in our limited Beta program.  Thank you!!
    </div>
  }

  export default SubscriptionDetail;