
import React, {useState, useEffect} from "react";
import {TextInput,minLength,SimpleForm,required,useNotify, maxLength, Placeholder, Toolbar} from "react-admin";
import { Auth } from 'aws-amplify';
import { LinearProgress } from '@mui/material';
import {  Container } from '@mui/material';


import {
    Chart as ChartJS,
    CategoryScale,
    Colors,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { GetUsage } from '../dataproviders/usage.js'  

import '../css/usage.css'
  
ChartJS.register(
    CategoryScale,
    Colors,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  


export const Usage = ({staticContext, ...props})=>{
    const [chartdata,setChartData]=useState();
    const [metrics,setMetrics]=useState();

    useEffect(()=>{

        const getUsage = ()=>{
            const analysesdata = {};
            const indexdata = {};
            let metrics={
                "totalanalyses":0,"totalpages":0
            }
            GetUsage().then((data)=>{
                
                
                metrics.totalanalyses=data.totals.total_analyses;
                metrics.totalpages=data.totals.total_pages_indexed;
                
                
                setMetrics(metrics);

                delete data.totals;
                
                setChartData({
                    labels: Object.keys(data),
                    datasets: [{
                      label: 'Analyses',
                      data: Object.keys(data).map((k)=>data[k].total_analyses),
                      borderWidth: 1,
                      //borderColor: '#36A2EB',
                      //backgroundColor: '#9BD0F5',
                    },{
                        label: 'Pages Indexed',
                        data: Object.keys(data).map((k)=>data[k].total_pages_indexed),
                        borderWidth: 1,
                        //borderColor: '#FF6384',
                        //backgroundColor: '#FFB1C1',
                    }]
                  });
                
            })
            .catch((err)=>{
                console.error(err);
            })
        }

        getUsage();


        

    },[staticContext]);

    const chartoptions =  {
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    }

    return (chartdata)
    ?<Container>
        <div className="chartContainer"><Bar  data={chartdata} options={chartoptions} /></div>
        <div>Total Analyses:  {`${metrics.totalanalyses}`}</div>
        <div>Total Pages Indexed:  {`${metrics.totalpages}`}</div>
     </Container>
    :<LinearProgress />
  }

  