// in src/MyAppBar.js
import React from 'react';
import { AppBar } from 'react-admin';
import { Box,Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';



import MyUserMenu from './menu';



const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});



const SiteAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} 
            userMenu={<MyUserMenu />}
        >
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        ></Typography>

        
        <span className="appBarLogo">
            <img src="/AscendAI_35.png" />
        </span>

        </AppBar>
    )
}

export default SiteAppBar;
