import { Auth,Amplify, API } from 'aws-amplify';


export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
		
		/*return new Promise((resolve,reject)=>{
			Auth.signIn(username, password).then((data)=>{

				console.log('successful login',data);
				resolve({ redirectTo: '/custom-url' });
			}).catch((err)=>{
				console.error('auth failed',err);
				reject();
			});
		});*/

    },
    // called when the user clicks on the logout button
    logout: () => {
		
		return new Promise((resolve,reject)=>{
			Auth.signOut().then(()=>{
				window.location.reload();
				resolve();
			}).catch((error)=>{
				reject(error);
			});
			
		});
		
		
		
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403 || status === 400) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {

		
			return new Promise((resolve,reject)=>{
				Auth.currentAuthenticatedUser().then((data)=>{
					if(data){
						resolve();
					}else{
						this.logout();
						//reject();
					}
				}).catch((error)=>{
					reject(error);
				});
			});
        
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};