import React from 'react';
import { Datagrid, TextField,  DateField, BooleanField, TextInput, useRecordContext,useListContext } from 'react-admin';
import { 
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    List,
    
} from 'react-admin';

import Button from '@mui/material/Button';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';


import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Box, Container,  Typography } from '@mui/material';
import { Route } from 'react-router';

import { Link } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { FileUpload } from "./fileupload";
import { Drawer } from '@material-ui/core'

import { GetDocumentUrl, GetDocumentObj } from "../dataproviders/documents";
import {isMobile} from 'react-device-detect';

import '../css/documents.css'



const DocLink = (props,ref) => {
    const record = useRecordContext();

    return(
        <Link {...props} to={`/documents/view/${record.id}`} target={`_view_${record.id}`}>{`${record.filename}`}</Link>
    )
}


const DocButton = (props,ref) => {
    const record = useRecordContext();

    const download = ()=>{
        if(isMobile){
            //if mobile, download the file directory
            GetDocumentObj(record.id).then((doc)=>{
                const buff = Buffer.from(doc.body, "base64");
                const pdf = new Blob([buff], { type: 'application/pdf' });
                const targeturl = URL.createObjectURL(pdf);
                
                const a = document.createElement('a');
                a.download = doc.filename;
                a.href = targeturl;
                a.addEventListener('click', (e) => {
                    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                });
                a.click();
            });
        }else{
            //open new tab using iframe
            window.open(`#/documents/view/${record.id}`, `_view_${record.id}`) 
        }
    }

    return(
        <Button {...props} to={`/documents/view/${record.id}`} target={`_view_${record.id}`} onClick={download}>{`${record.filename}`}</Button>
    )
}

const Filters = [
    <TextInput label="Document" source="filename" defaultValue="" />,
    <TextInput label="Document Set" source="docsetname" defaultValue="" />,
    <TextInput label="Status" source="status" defaultValue="" />,
]


export const DocumentsList = (props) => {
    
    const { data, isLoading } = useListContext();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const toggleDrawer =
      (anchor, open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
        console.log(`setting anchor state to ${anchor}`);
        setState({ ...state, [anchor]: open });
      };

      const ListActions = () => (
            <TopToolbar>
                <FilterButton/>
                <Button
                    onClick={toggleDrawer('right', true)}
                    label="upload"
                >
                    <UploadFileIcon/>
                </Button>
                <ExportButton/>
            </TopToolbar>
        );

        const Empty = () => (
            <Container>
                 <div className="question_noout_panel">
                    <div className="question_noout_container">
                    
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                                <li><DescriptionIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Documents</li>
                                <li>Upload PDF formatted documents to be used in AI Driven Assessments and Realtime Analysis.  Documents
                                    are queued for indexing upon upload.  Documents will reflect a COMPLETE status after indexing, at which point they may be
                                    targeted for Assessment and Analysis.
                                </li>
                            </ul>
                        </div>
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                            
                                <li><DocumentScannerIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Document Sets</li>
                                <li>Documents are organized into Document Sets.  Each document may be associated with one Document Set.  Document Sets are used
                                    to filter your Documents view or target Assesment and Analysis across many documents at once.
                                </li>
                                
                            </ul>
                        </div>
                        <div className="question_noout_child">
                            <ul className="infodisplay">
                                <li><PlagiarismIcon color="info" sx={{fontSize:'72px'}} /></li>
                                <li className="heading">Realtime Analysis</li>
                                <li>
                                    You are ready to launch your first Realtime Analysis after you've uploaded Documents are indexed.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Container className="getstarted">
                    <div>Get started and upload your first file now!</div>
                    <div>
                        <Tooltip title="Upload">
                            <Button
                                onClick={toggleDrawer('right', true)}
                                label="upload"
                                color="info"
                                sx={{fontSize:'72px'}}>
                                <UploadFileIcon fontSize="inherit" />
                            </Button>
                        </Tooltip>
                    </div>
                </Container>
            </Container>
        );
    

    if (isLoading) return null;
    return (
        <React.Fragment>
            <List {...props}  filters={Filters} 
                    actions={<ListActions/>}
                    empty={<Empty />} >
                <Datagrid>
                    <TextField source="docsetname" label="Document Set" />
                    <DocButton source="filename" label="Document" />
                    <DateField source="created_date" label="Uploaded" />
                    <TextField source="status" label="Indexing Status"  />
                </Datagrid>
            </List>
           
            <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                <FileUpload {...props} />
            </Drawer>
           
        </React.Fragment>
    )
}
