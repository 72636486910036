import { API, Amplify} from 'aws-amplify';


const apiName = 'default';

const delay = (time)=>{
	return new Promise(resolve => setTimeout(resolve, time));
}

const AnalyzeDocument = async(params)=>{
    return new Promise(async (resolve,reject)=>{
       
       let jsonpost = params.data;
       let restconf = { headers:{"Content-Type":"application/json"}, body:jsonpost };
       resolve(API.post(apiName,'/app/analyzedocument',restconf))

    });

}

export { AnalyzeDocument }

