import React, {useState, useEffect} from "react";
import {TextInput,minLength,SimpleForm,required,useNotify, maxLength, Placeholder, Toolbar} from "react-admin";
import { Auth } from 'aws-amplify';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { TextField } from "react-admin";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import QRCodeSVG from 'qrcode.react';

import ChangeMfaToolbar from "./changemfatoolbar";



const MfaSetup = (props)=>{
    const [qrcodeurl, setQrCodeUrl] = useState();
    const [qrcode, setQrCode] = useState();
    const [verifyotp, setVerifyOtp] = useState('');
    const [preferredmfa, setPreferredMFA] = useState('');
    const [currentuser, setCurrentUser] = useState();
    const [formvalues, setFormValues] = useState({});
    const [showmfaconfig, setMfaConfigDisplay] = useState(false);
    const [issaving,setIsSaving]=useState(false);
    const [saveButtonDisabled,setSaveButtonDisabled]=useState(true);
    const notify = useNotify();
    
    

    useEffect(()=>{
        
        const dootp = async(user)=>{
            let code = await Auth.setupTOTP(user);
            const str = "otpauth://totp/AWSCognito:"+ user.attributes.email + "?secret=" + code + "&issuer=AscendAI.io";
            setQrCodeUrl(str);
            setQrCode(code);
        }
       
        
        const getPreferredMFA = async(user)=>{
            try{
                let prefmfa = await Auth.getPreferredMFA(user, {bypassCache: true});
                setPreferredMFA(prefmfa);
                //formvalues[event.target.name]=event.target.value;
            }catch(err){
                setPreferredMFA('NOMFA');
                console.error(err);
            }

            setFormValues(formvalues);
        }
  
  
        if(props.CurrentUser){
            setCurrentUser(props.CurrentUser);
            const user = props.CurrentUser;
            dootp(user);
            getPreferredMFA(user);
            
        }
        
          
    },[]);

     
    const formfieldchange=(event)=>{
        
        switch(event.target.type){
            case 'radio':
                formvalues[event.target.name]=event.target.value;
                if(event.target.name=='mfa-settings-group'){
                    if((event.target.value)=='SOFTWARE_TOKEN_MFA' && !showmfaconfig && event.target.value!=preferredmfa){
                        setMfaConfigDisplay(true);
                    }else if(showmfaconfig){
                        setMfaConfigDisplay(false);
                    }
                    setSaveButtonDisabled((event.target.value==preferredmfa));
                }
            break;
            default:
                formvalues[event.target.id]=event.target.value;

            break;
        }
        
        setFormValues(formvalues);
        
    }
   
    const handlechange=(event)=>{
        setVerifyOtp(event.target.value);
    }

 
    const onSubmit = () => {
        
        setIsSaving(true);
        setSaveButtonDisabled(true);
        
        switch(formvalues['mfa-settings-group']){
            case "NOMFA":
                Auth.setPreferredMFA(currentuser,'NOMFA')
                .then((data)=>{
                    setPreferredMFA('NOMFA');
                    notify("Multi-Factor Authentication Disabled.");
                })
                .catch((error)=>{
                    console.error(error);
                    notify(JSON.stringify(error));
                })
                .finally(()=>{
                    setSaveButtonDisabled(true);
                    setIsSaving(false);
                })
            break;
            case "SOFTWARE_TOKEN_MFA":
                const otp=formvalues['txtmfa'];
                if(otp.length==6){
                    Auth.verifyTotpToken(currentuser, otp)
                    .then(() => {
                        //set TOTP as the preferred method
                        Auth.setPreferredMFA(currentuser, 'TOTP');
                        setPreferredMFA('SOFTWARE_TOKEN_MFA');
                        notify('Completed MFA token setup.')
                        setSaveButtonDisabled(true);
                        setMfaConfigDisplay(false);
                        formvalues['txtmfa']='';
                        setFormValues(formvalues);
                    })
                    .catch((e) => {
                    // Token is not verified
                        console.error(e);
                        notify('Error completing MFA tokens setup.')
                    }).finally(()=>{
                        
                        setIsSaving(false);
                    })
                }
            break;


        }

       
        /*Auth.changePassword(currentuser, formvalues['crrpwd'], formvalues['newpwd1'])
        .then((data)=>{
            notify("Password changed successfully.");
        })
        .catch((error)=>{
            notify(`Error changing password.  Check your current Password and try again.  ${error.name}`);
        })
        .finally(()=>{
            setFormValues({});
            setIsSaving(false);
        });
        */
        
    
    };


    return (currentuser && preferredmfa!='')?(
        <div className="flex-container">
            <div className="flex-child">
                <SimpleForm toolbar={<ChangeMfaToolbar onSubmit={onSubmit} label="Change MFA Settings" saving={issaving} saveButtonDisabled={saveButtonDisabled} />}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="mfa-settings"
                            name="mfa-settings-group"
                            defaultValue={preferredmfa}
                            validate={[required()]}
                        >
                            <FormControlLabel value="NOMFA" control={<Radio id="rdonomfa" defaultChecked={true} /> } onChange={formfieldchange} label="Disable MFA"  />
                            <FormControlLabel value="SOFTWARE_TOKEN_MFA" control={<Radio id="rdototp" />} label="Authenticator App (TOTP)"  onChange={formfieldchange} />
                        </RadioGroup>
                    </FormControl>

                    {(showmfaconfig)
                        ?
                            <Container>
                                <div>{(qrcodeurl)?<QRCodeSVG value={qrcodeurl}/>:<span />}</div>
                                <div>Key:  {`${qrcode}`}</div>
                                <div><Button>Show Code</Button></div>
                                <div><TextInput id="txtmfa" source="txtmfa" label="Verification Code" variant="outlined" type="text" value={verifyotp} onChange={formfieldchange} /></div>
                                
                            </Container>
                        :   <span />
                }
                </SimpleForm>
            </div>
            <div className={`flex-child instructions`}>
                    {(showmfaconfig)
                            ?<ol>
                                <li>Protect the QR Code and Key displayed at left as you would protect a password.</li>
                                <li>Scan the QR Code using your Microsoft or Google Authenticator application, or manually enter the Key.</li>
                                <li>Enter the Verification Code displayed by your Authenticator application and click the button to Save.</li>
                             </ol>
                            :(preferredmfa!='SOFTWARE_TOKEN_MFA')
                                ?<div>Select the Authenticator App (TOTP) option to Enable Multi-Factor Authentication.</div>
                                :<div>MFA is enabled.  You may disable MFA by selecting the Disable MFA option and clicking the button to Save.</div>


                    }
               
            </div>
        </div>
    ):<Placeholder />
  }

  export default MfaSetup;