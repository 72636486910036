import { API, Amplify} from 'aws-amplify';


const apiName = 'default';


const GetFramework = async(framework)=>{

    return new Promise((resolve,reject)=>{

        let listdata = {"data":[],"total":0}
        switch(framework){
            case 'NISTCSF':
                listdata.data = GetNistCSF();
                listdata.total = listdata.data.length
            break;
            case 'FFIECCAT':
                listdata.data = GetFFIECCAT();
                listdata.total = listdata.data.length
            break;
            case 'CSC8':
                listdata.data = GetCSC8();
                listdata.total = listdata.data.length
            break;
        }
        resolve(listdata);

    })
    
    
}

const GetCSC8 = () =>{
    return ([
          { id:'1.1', datapoint:'1.1 - Establish and Maintain Detailed Enterprise Asset Inventory'}
        , { id:'1.2', datapoint:'1.2 - Address Unauthorized Assets'}
        , { id:'1.3', datapoint:'1.3 - Utilize an Active Discovery Tool'}
        , { id:'1.4', datapoint:'1.4 - Use Dynamic Host Configuration Protocol (DHCP) Logging to Update Enterprise Asset Inventory'}
        , { id:'1.5', datapoint:'1.5 - Use a Passive Asset Discovery Tool'}
        , { id:'2.1', datapoint:'2.1 - Establish and Maintain a Software Inventory'}
        , { id:'2.2', datapoint:'2.2 - Ensure Authorized Software is Currently Supported '}
        , { id:'2.3', datapoint:'2.3 - Address Unauthorized Software'}
        , { id:'2.4', datapoint:'2.4 - Utilize Automated Software Inventory Tools'}
        , { id:'2.5', datapoint:'2.5 - Allowlist Authorized Software'}
        , { id:'2.6', datapoint:'2.6 - Allowlist Authorized Libraries'}
        , { id:'2.7', datapoint:'2.7 - Allowlist Authorized Scripts'}
        , { id:'3.1', datapoint:'3.1 - Establish and Maintain a Data Management Process'}
        , { id:'3.2', datapoint:'3.2 - Establish and Maintain a Data Inventory'}
        , { id:'3.3', datapoint:'3.3 - Configure Data Access Control Lists'}
        , { id:'3.4', datapoint:'3.4 - Enforce Data Retention'}
        , { id:'3.5', datapoint:'3.5 - Securely Dispose of Data'}
        , { id:'3.6', datapoint:'3.6 - Encrypt Data on End-User Devices'}
        , { id:'3.7', datapoint:'3.7 - Establish and Maintain a Data Classification Scheme'}
        , { id:'3.8', datapoint:'3.8 - Document Data Flows'}
        , { id:'3.9', datapoint:'3.9 - Encrypt Data on Removable Media'}
        , { id:'3.10', datapoint:'3.10 - Encrypt Sensitive Data in Transit'}
        , { id:'3.11', datapoint:'3.11 - Encrypt Sensitive Data at Rest'}
        , { id:'3.12', datapoint:'3.12 - Segment Data Processing and Storage Based on Sensitivity'}
        , { id:'3.13', datapoint:'3.13 - Deploy a Data Loss Prevention Solution'}
        , { id:'3.14', datapoint:'3.14 - Log Sensitive Data Access'}
        , { id:'4.1', datapoint:'4.1 - Establish and Maintain a Secure Configuration Process'}
        , { id:'4.2', datapoint:'4.2 - Establish and Maintain a Secure Configuration Process for Network Infrastructure'}
        , { id:'4.3', datapoint:'4.3 - Configure Automatic Session Locking on Enterprise Assets'}
        , { id:'4.4', datapoint:'4.4 - Implement and Manage a Firewall on Servers'}
        , { id:'4.5', datapoint:'4.5 - Implement and Manage a Firewall on End-User Devices'}
        , { id:'4.6', datapoint:'4.6 - Securely Manage Enterprise Assets and Software'}
        , { id:'4.7', datapoint:'4.7 - Manage Default Accounts on Enterprise Assets and Software'}
        , { id:'4.8', datapoint:'4.8 - Uninstall or Disable Unnecessary Services on Enterprise Assets and Software'}
        , { id:'4.9', datapoint:'4.9 - Configure Trusted DNS Servers on Enterprise Assets'}
        , { id:'4.10', datapoint:'4.10 - Enforce Automatic Device Lockout on Portable End-User Devices'}
        , { id:'4.11', datapoint:'4.11 - Enforce Remote Wipe Capability on Portable End-User Devices'}
        , { id:'4.12', datapoint:'4.12 - Separate Enterprise Workspaces on Mobile End-User Devices'}
        , { id:'5.1', datapoint:'5.1 - Establish and Maintain an Inventory of Accounts'}
        , { id:'5.2', datapoint:'5.2 - Use Unique Passwords'}
        , { id:'5.3', datapoint:'5.3 - Disable Dormant Accounts'}
        , { id:'5.4', datapoint:'5.4 - Restrict Administrator Privileges to Dedicated Administrator Accounts'}
        , { id:'5.5', datapoint:'5.5 - Establish and Maintain an Inventory of Service Accounts'}
        , { id:'5.6', datapoint:'5.6 - Centralize Account Management'}
        , { id:'6.1', datapoint:'6.1 - Establish an Access Granting Process'}
        , { id:'6.2', datapoint:'6.2 - Establish an Access Revoking Process'}
        , { id:'6.3', datapoint:'6.3 - Require MFA for Externally-Exposed Applications'}
        , { id:'6.4', datapoint:'6.4 - Require MFA for Remote Network Access'}
        , { id:'6.5', datapoint:'6.5 - Require MFA for Administrative Access'}
        , { id:'6.6', datapoint:'6.6 - Establish and Maintain an Inventory of Authentication and Authorization Systems'}
        , { id:'6.7', datapoint:'6.7 - Centralize Access Control'}
        , { id:'6.8', datapoint:'6.8 - Define and Maintain Role-Based Access Control'}
        , { id:'7.1', datapoint:'7.1 - Establish and Maintain a Vulnerability Management Process'}
        , { id:'7.2', datapoint:'7.2 - Establish and Maintain a Remediation Process'}
        , { id:'7.3', datapoint:'7.3 - Perform Automated Operating System Patch Management'}
        , { id:'7.4', datapoint:'7.4 - Perform Automated Application Patch Management'}
        , { id:'7.5', datapoint:'7.5 - Perform Automated Vulnerability Scans of Internal Enterprise Assets'}
        , { id:'7.6', datapoint:'7.6 - Perform Automated Vulnerability Scans of Externally-Exposed Enterprise Assets'}
        , { id:'7.7', datapoint:'7.7 - Remediate Detected Vulnerabilities'}
        , { id:'8.1', datapoint:'8.1 - Establish and Maintain an Audit Log Management Process'}
        , { id:'8.2', datapoint:'8.2 - Collect Audit Logs'}
        , { id:'8.3', datapoint:'8.3 - Ensure Adequate Audit Log Storage'}
        , { id:'8.4', datapoint:'8.4 - Standardize Time Synchronization'}
        , { id:'8.5', datapoint:'8.5 - Collect Detailed Audit Logs'}
        , { id:'8.6', datapoint:'8.6 - Collect DNS Query Audit Logs'}
        , { id:'8.7', datapoint:'8.7 - Collect URL Request Audit Logs'}
        , { id:'8.8', datapoint:'8.8 - Collect Command-Line Audit Logs'}
        , { id:'8.9', datapoint:'8.9 - Centralize Audit Logs'}
        , { id:'8.10', datapoint:'8.10 - Retain Audit Logs'}
        , { id:'8.11', datapoint:'8.11 - Conduct Audit Log Reviews'}
        , { id:'8.12', datapoint:'8.12 - Collect Service Provider Logs'}
        , { id:'9.1', datapoint:'9.1 - Ensure Use of Only Fully Supported Browsers and Email Clients'}
        , { id:'9.2', datapoint:'9.2 - Use DNS Filtering Services'}
        , { id:'9.3', datapoint:'9.3 - Maintain and Enforce Network-Based URL Filters'}
        , { id:'9.4', datapoint:'9.4 - Restrict Unnecessary or Unauthorized Browser and Email Client Extensions'}
        , { id:'9.5', datapoint:'9.5 - Implement DMARC'}
        , { id:'9.6', datapoint:'9.6 - Block Unnecessary File Types'}
        , { id:'9.7', datapoint:'9.7 - Deploy and Maintain Email Server Anti-Malware Protections'}
        , { id:'10.1', datapoint:'10.1 - Deploy and Maintain Anti-Malware Software'}
        , { id:'10.2', datapoint:'10.2 - Configure Automatic Anti-Malware Signature Updates'}
        , { id:'10.3', datapoint:'10.3 - Disable Autorun and Autoplay for Removable Media'}
        , { id:'10.4', datapoint:'10.4 - Configure Automatic Anti-Malware Scanning of Removable Media'}
        , { id:'10.5', datapoint:'10.5 - Enable Anti-Exploitation Features'}
        , { id:'10.6', datapoint:'10.6 - Centrally Manage Anti-Malware Software'}
        , { id:'10.7', datapoint:'10.7 - Use Behavior-Based Anti-Malware Software'}
        , { id:'11.1', datapoint:'11.1 - Establish and Maintain a Data Recovery Process '}
        , { id:'11.2', datapoint:'11.2 - Perform Automated Backups '}
        , { id:'11.3', datapoint:'11.3 - Protect Recovery Data'}
        , { id:'11.4', datapoint:'11.4 - Establish and Maintain an Isolated Instance of Recovery Data '}
        , { id:'11.5', datapoint:'11.5 - Test Data Recovery'}
        , { id:'12.1', datapoint:'12.1 - Ensure Network Infrastructure is Up-to-Date'}
        , { id:'12.2', datapoint:'12.2 - Establish and Maintain a Secure Network Architecture'}
        , { id:'12.3', datapoint:'12.3 - Securely Manage Network Infrastructure'}
        , { id:'12.4', datapoint:'12.4 - Establish and Maintain Architecture Diagram(s)'}
        , { id:'12.5', datapoint:'12.5 - Centralize Network Authentication, Authorization, and Auditing (AAA)'}
        , { id:'12.6', datapoint:'12.6 - Use of Secure Network Management and Communication Protocols '}
        , { id:'12.7', datapoint:'12.7 - Ensure Remote Devices Utilize a VPN and are Connecting to an Enterprise’s AAA Infrastructure'}
        , { id:'12.8', datapoint:'12.8 - Establish and Maintain Dedicated Computing Resources for All Administrative Work'}
        , { id:'13.1', datapoint:'13.1 - Centralize Security Event Alerting'}
        , { id:'13.2', datapoint:'13.2 - Deploy a Host-Based Intrusion Detection Solution'}
        , { id:'13.3', datapoint:'13.3 - Deploy a Network Intrusion Detection Solution'}
        , { id:'13.4', datapoint:'13.4 - Perform Traffic Filtering Between Network Segments'}
        , { id:'13.5', datapoint:'13.5 - Manage Access Control for Remote Assets'}
        , { id:'13.6', datapoint:'13.6 - Collect Network Traffic Flow Logs '}
        , { id:'13.7', datapoint:'13.7 - Deploy a Host-Based Intrusion Prevention Solution'}
        , { id:'13.8', datapoint:'13.8 - Deploy a Network Intrusion Prevention Solution'}
        , { id:'13.9', datapoint:'13.9 - Deploy Port-Level Access Control'}
        , { id:'13.10', datapoint:'13.10 - Perform Application Layer Filtering'}
        , { id:'13.11', datapoint:'13.11 - Tune Security Event Alerting Thresholds'}
        , { id:'14.1', datapoint:'14.1 - Establish and Maintain a Security Awareness Program'}
        , { id:'14.2', datapoint:'14.2 - Train Workforce Members to Recognize Social Engineering Attacks'}
        , { id:'14.3', datapoint:'14.3 - Train Workforce Members on Authentication Best Practices'}
        , { id:'14.4', datapoint:'14.4 - Train Workforce on Data Handling Best Practices'}
        , { id:'14.5', datapoint:'14.5 - Train Workforce Members on Causes of Unintentional Data Exposure'}
        , { id:'14.6', datapoint:'14.6 - Train Workforce Members on Recognizing and Reporting Security Incidents'}
        , { id:'14.7', datapoint:'14.7 - Train Workforce on How to Identify and Report if Their Enterprise Assets are Missing Security Updates'}
        , { id:'14.8', datapoint:'14.8 - Train Workforce on the Dangers of Connecting to and Transmitting Enterprise Data Over Insecure Networks'}
        , { id:'14.9', datapoint:'14.9 - Conduct Role-Specific Security Awareness and Skills Training'}
        , { id:'15.1', datapoint:'15.1 - Establish and Maintain an Inventory of Service Providers'}
        , { id:'15.2', datapoint:'15.2 - Establish and Maintain a Service Provider Management Policy'}
        , { id:'15.3', datapoint:'15.3 - Classify Service Providers'}
        , { id:'15.4', datapoint:'15.4 - Ensure Service Provider Contracts Include Security Requirements'}
        , { id:'15.5', datapoint:'15.5 - Assess Service Providers'}
        , { id:'15.6', datapoint:'15.6 - Monitor Service Providers'}
        , { id:'15.7', datapoint:'15.7 - Securely Decommission Service Providers'}
        , { id:'16.1', datapoint:'16.1 - Establish and Maintain a Secure Application Development Process'}
        , { id:'16.2', datapoint:'16.2 - Establish and Maintain a Process to Accept and Address Software Vulnerabilities'}
        , { id:'16.3', datapoint:'16.3 - Perform Root Cause Analysis on Security Vulnerabilities'}
        , { id:'16.4', datapoint:'16.4 - Establish and Manage an Inventory of Third-Party Software Components'}
        , { id:'16.5', datapoint:'16.5 - Use Up-to-Date and Trusted Third-Party Software Components'}
        , { id:'16.6', datapoint:'16.6 - Establish and Maintain a Severity Rating System and Process for Application Vulnerabilities'}
        , { id:'16.7', datapoint:'16.7 - Use Standard Hardening Configuration Templates for Application Infrastructure'}
        , { id:'16.8', datapoint:'16.8 - Separate Production and Non-Production Systems'}
        , { id:'16.9', datapoint:'16.9 - Train Developers in Application Security Concepts and Secure Coding'}
        , { id:'16.10', datapoint:'16.10 - Apply Secure Design Principles in Application Architectures'}
        , { id:'16.11', datapoint:'16.11 - Leverage Vetted Modules or Services for Application Security Components'}
        , { id:'16.12', datapoint:'16.12 - Implement Code-Level Security Checks'}
        , { id:'16.13', datapoint:'16.13 - Conduct Application Penetration Testing'}
        , { id:'16.14', datapoint:'16.14 - Conduct Threat Modeling'}
        , { id:'17.1', datapoint:'17.1 - Designate Personnel to Manage Incident Handling'}
        , { id:'17.2', datapoint:'17.2 - Establish and Maintain Contact Information for Reporting Security Incidents'}
        , { id:'17.3', datapoint:'17.3 - Establish and Maintain an Enterprise Process for Reporting Incidents'}
        , { id:'17.4', datapoint:'17.4 - Establish and Maintain an Incident Response Process'}
        , { id:'17.5', datapoint:'17.5 - Assign Key Roles and Responsibilities'}
        , { id:'17.6', datapoint:'17.6 - Define Mechanisms for Communicating During Incident Response'}
        , { id:'17.7', datapoint:'17.7 - Conduct Routine Incident Response Exercises'}
        , { id:'17.8', datapoint:'17.8 - Conduct Post-Incident Reviews'}
        , { id:'17.9', datapoint:'17.9 - Establish and Maintain Security Incident Thresholds'}
        , { id:'18.1', datapoint:'18.1 - Establish and Maintain a Penetration Testing Program'}
        , { id:'18.2', datapoint:'18.2 - Perform Periodic External Penetration Tests'}
        , { id:'18.3', datapoint:'18.3 - Remediate Penetration Test Findings'}
        , { id:'18.4', datapoint:'18.4 - Validate Security Measures'}
        , { id:'18.5', datapoint:'18.5 - Perform Periodic Internal Penetration Tests'}

    ])
}
const GetFFIECCAT = ()=>{
    return ([
          { id:'D1.G.Ov.B.1', datapoint:'D1.G.Ov.B.1 - Designated members of management are held accountable by the board or an appropriate board committee for implementing and managing the information security and business continuity programs. (FFIEC Information Security Booklet, page 3)'}
        , { id:'D1.G.Ov.B.2', datapoint:'D1.G.Ov.B.2 - Information security risks are discussed in management meetings when prompted by highly visible cyber events or regulatory alerts. (FFIEC Information Security Booklet, page 6) '}
        , { id:'D1.G.Ov.B.3', datapoint:'D1.G.Ov.B.3 - Management provides a written report on the overall status of the information security and business continuity programs to the board or an appropriate board committee at least annually. (FFIEC Information Security Booklet, page 5)'}
        , { id:'D1.G.Ov.B.4', datapoint:'D1.G.Ov.B.4 - The budgeting process includes information security related expenses and tools. (FFIEC E-Banking Booklet, page 20) '}
        , { id:'D1.G.Ov.B.5', datapoint:'D1.G.Ov.B.5 - Management considers the risks posed by other critical infrastructures (e.g., telecommunications, energy) to the institution. (FFIEC Business Continuity Planning Booklet, page J-12) '}
        , { id:'D1.G.Ov.E.1', datapoint:'D1.G.Ov.E.1 - At least annually, the board or an appropriate board committee reviews and approves the institution’s cybersecurity program'}
        , { id:'D1.G.Ov.E.2', datapoint:'D1.G.Ov.E.2 - Management is responsible for ensuring compliance with legal and regulatory requirements related to cybersecurity. '}
        , { id:'D1.G.Ov.E.3', datapoint:'D1.G.Ov.E.3 - Cybersecurity tools and staff are requested through the budget process. '}
        , { id:'D1.G.Ov.E.4', datapoint:'D1.G.Ov.E.4 - There is a process to formally discuss and estimate potential expenses associated with cybersecurity incidents as part of the budgeting process'}
        , { id:'D1.G.Ov.Int.1', datapoint:'D1.G.Ov.Int.1 - The board or an appropriate board committee has cybersecurity expertise or engages experts to assist with oversight responsibilities. '}
        , { id:'D1.G.Ov.Int.2', datapoint:'D1.G.Ov.Int.2 - The standard board meeting package includes reports and metrics that go beyond events and incidents to address threat intelligence trends and the institution’s security posture. '}
        , { id:'D1.G.Ov.Int.3', datapoint:'D1.G.Ov.Int.3 - The institution has a cyber risk appetite statement approved by the board or an appropriate board committee.'}
        , { id:'D1.G.Ov.Int.4', datapoint:'D1.G.Ov.Int.4 - Cyber risks that exceed the risk appetite are escalated to management. '}
        , { id:'D1.G.Ov.Int.5', datapoint:'D1.G.Ov.Int.5 - The board or an appropriate board committee ensures management’s annual cybersecurity self-assessment evaluates the institution’s ability to meet its cyber risk management standards.'}
        , { id:'D1.G.Ov.Int.6', datapoint:'D1.G.Ov.Int.6 - The board or an appropriate board committee reviews and approves management’s prioritization and resource allocation decisions based on the results of the cyber assessments. '}
        , { id:'D1.G.Ov.Int.7', datapoint:'D1.G.Ov.Int.7 - The board or an appropriate board committee ensures management takes appropriate actions to address changing cyber risks or significant cybersecurity issues. '}
        , { id:'D1.G.Ov.Int.8', datapoint:'D1.G.Ov.Int.8 - The budget process for requesting additional cybersecurity staff and tools is integrated into business units’ budget processes. '}
        , { id:'D1.G.Ov.A.1', datapoint:'D1.G.Ov.A.1 - The board or board committee approved cyber risk appetite statement is part of the enterprise-wide risk appetite statement.'}
        , { id:'D1.G.Ov.A.2', datapoint:'D1.G.Ov.A.2 - Management has a formal process to continuously improve cybersecurity oversight. '}
        , { id:'D1.G.Ov.A.3', datapoint:'D1.G.Ov.A.3 - The budget process for requesting additional cybersecurity staff and tools maps current resources and tools to the cybersecurity strategy. '}
        , { id:'D1.G.Ov.A.4', datapoint:'D1.G.Ov.A.4 - Management and the board or an appropriate board committee hold business units accountable for effectively managing all cyber risks associated with their activities. '}
        , { id:'D1.G.Ov.A.5', datapoint:'D1.G.Ov.A.5 - Management identifies root cause(s) when cyber attacks result in material loss. '}
        , { id:'D1.G.Ov.A.6', datapoint:'D1.G.Ov.A.6 - The board or an appropriate board committee ensures that management’s actions consider the cyber risks that the institution poses to the financial sector.'}
        , { id:'D1.G.Ov.Inn.1', datapoint:'D1.G.Ov.Inn.1 - The board or an appropriate board committee discusses ways for management to develop cybersecurity improvements that may be adopted sector-wide. '}
        , { id:'D1.G.Ov.Inn.2', datapoint:'D1.G.Ov.Inn.2 - The board or an appropriate board committee verifies that management’s actions consider the cyber risks that the institution poses to other critical infrastructures (e.g., telecommunications, energy).  '}
        , { id:'D1.G.SP.B.1', datapoint:'D1.G.SP.B.1 - The institution has an information security strategy that integrates technology, policies, procedures, and training to mitigate risk. (FFIEC Information Security Booklet, page 3)'}
        , { id:'D1.G.SP.B.2', datapoint:'D1.G.SP.B.2 - The institution has policies commensurate with its risk and complexity that address the concepts of information technology risk management. (FFIEC Information Security Booklet, page, 16)'}
        , { id:'D1.G.SP.B.3', datapoint:'D1.G.SP.B.3 - The institution has policies commensurate with its risk and complexity that address the concepts of threat information sharing. (FFIEC EBanking Booklet, page 28)'}
        , { id:'D1.G.SP.B.4', datapoint:'D1.G.SP.B.4 - The institution has board-approved policies commensurate with its risk and complexity that address information security. (FFIEC Information Security Booklet, page 16) '}
        , { id:'D1.G.SP.B.5', datapoint:'D1.G.SP.B.5 - The institution has policies commensurate with its risk and complexity that address the concepts of external dependency or third-party management. (FFIEC Outsourcing Booklet, page 2) '}
        , { id:'D1.G.SP.B.6', datapoint:'D1.G.SP.B.6 - The institution has policies commensurate with its risk and complexity that address the concepts of incident response and resilience. (FFIEC Information Security Booklet, page 83)'}
        , { id:'D1.G.SP.B.7', datapoint:'D1.G.SP.B.7 - All elements of the information security program are coordinated enterprise-wide. (FFIEC Information Security Booklet, page 7'}
        , { id:'D1.G.SP.E.1', datapoint:'D1.G.SP.E.1 - The institution augmented its information security strategy to incorporate cybersecurity and resilience.'}
        , { id:'D1.G.SP.E.2', datapoint:'D1.G.SP.E.2 - The institution has a formal cybersecurity program that is based on technology and security industry standards or benchmarks.'}
        , { id:'D1.G.SP.E.3', datapoint:'D1.G.SP.E.3 - A formal process is in place to update policies as the institution’s inherent risk profile changes. '}
        , { id:'D1.G.SP.Int.1', datapoint:'D1.G.SP.Int.1 - The institution has a comprehensive set of policies commensurate with its risk and complexity that address the concepts of threat intelligence.'}
        , { id:'D1.G.SP.A.1', datapoint:'D1.G.SP.A.1 - Management periodically reviews the cybersecurity strategy to address evolving cyber threats and changes to the institution’s inherent risk profile.'}
        , { id:'D1.G.SP.A.2', datapoint:'D1.G.SP.A.2 - The cybersecurity strategy is incorporated into, or conceptually fits within, the institution’s enterprise-wide risk management strategy. '}
        , { id:'D1.G.SP.A.3', datapoint:'D1.G.SP.A.3 - Management links strategic cybersecurity objectives to tactical goals. '}
        , { id:'D1.G.SP.A.4', datapoint:'D1.G.SP.A.4 - A formal process is in place to cross-reference and simultaneously update all policies related to cyber risks across business lines.'}
        , { id:'D1.G.SP.A.5', datapoint:'D1.G.SP.A.5 - The cybersecurity strategy outlines the institution’s future state of cybersecurity with short-term and long-term perspectives. '}
        , { id:'D1.G.SP.A.6', datapoint:'D1.G.SP.A.6 - Industry-recognized cybersecurity standards are used as sources during the analysis of cybersecurity program gaps.'}
        , { id:'D1.G.SP.A.7', datapoint:'D1.G.SP.A.7 - The cybersecurity strategy identifies and communicates the institution’s role as a component of critical infrastructure in the financial services industry. '}
        , { id:'D1.G.SP.A.8', datapoint:'D1.G.SP.A.8 - The risk appetite is informed by the institution’s role in critical infrastructure. '}
        , { id:'D1.G.SP.A.9', datapoint:'D1.G.SP.A.9 - Management is continuously improving the existing cybersecurity program to adapt as the desired cybersecurity target state changes.'}
        , { id:'D1.G.SP.Inn.1', datapoint:'D1.G.SP.Inn.1 - The cybersecurity strategy identifies and communicates the institution’s role as it relates to other critical infrastructures. '}
        , { id:'D1.G.IT.B.1', datapoint:'D1.G.IT.B.1 - An inventory of organizational assets (e.g., hardware, software, data, and systems hosted externally) is maintained. (FFIEC Information Security Booklet, page 9) '}
        , { id:'D1.G.IT.B.2', datapoint:'D1.G.IT.B.2 - Organizational assets (e.g., hardware, systems, data, and applications) are prioritized for protection based on the data classification and business value. (FFIEC Information Security Booklet, page 12) '}
        , { id:'D1.G.IT.B.3', datapoint:'D1.G.IT.B.3 - Management assigns accountability for maintaining an inventory of organizational assets. (FFIEC Information Security Booklet, page 9) '}
        , { id:'D1.G.IT.B.4', datapoint:'D1.G.IT.B.4 - A change management process is in place to request and approve changes to systems configurations, hardware, software, applications, and security tools. (FFIEC Information Security Booklet, page 56)'}
        , { id:'D1.G.IT.E.1', datapoint:'D1.G.IT.E.1 - The asset inventory, including identification of critical assets, is updated at least annually to address new, relocated, re-purposed, and sunset assets. '}
        , { id:'D1.G.IT.E.2', datapoint:'D1.G.IT.E.2 - The institution has a documented asset life-cycle process that considers whether assets to be acquired have appropriate security safeguards. '}
        , { id:'D1.G.IT.E.3', datapoint:'D1.G.IT.E.3 - The institution proactively manages system EOL (e.g., replacement) to limit security risks. '}
        , { id:'D1.G.IT.E.4', datapoint:'D1.G.IT.E.4 - Changes are formally approved by an individual or committee with appropriate authority and with separation of duties. '}
        , { id:'D1.G.IT.Int.1', datapoint:'D1.G.IT.Int.1 - Baseline configurations cannot be altered without a formal change request, documented approval, and an assessment of security implications.'}
        , { id:'D1.G.IT.Int.2', datapoint:'D1.G.IT.Int.2 - A formal IT change management process requires cybersecurity risk to be evaluated during the analysis, approval, testing, and reporting of changes'}
        , { id:'D1.G.IT.A.1', datapoint:'D1.G.IT.A.1 - Supply chain risk is reviewed before the acquisition of mission-critical information systems including system components. '}
        , { id:'D1.G.IT.A.2', datapoint:'D1.G.IT.A.2 - Automated tools enable tracking, updating, asset prioritizing, and custom reporting of the asset inventory. '}
        , { id:'D1.G.IT.A.3', datapoint:'D1.G.IT.A.3 - Automated processes are in place to detect and block unauthorized changes to software and hardware. '}
        , { id:'D1.G.IT.A.4', datapoint:'D1.G.IT.A.4 - The change management system uses thresholds to determine when a risk assessment of the impact of the change is required.'}
        , { id:'D1.G.IT.Inn.1', datapoint:'D1.G.IT.Inn.1 - A formal change management function governs decentralized or highly distributed change requests and identifies and measures security risks that may cause increased exposure to cyber attack. '}
        , { id:'D1.G.IT.Inn.2', datapoint:'D1.G.IT.Inn.2 - Comprehensive automated enterprise tools are implemented to detect and block unauthorized changes to software and hardware. '}
        , { id:'D1.RM.RMP.B.1', datapoint:'D1.RM.RMP.B.1 - An information security and business continuity risk management function(s) exists within the institution. (FFIEC Information Security Booklet, page 68)'}
        , { id:'D1.RM.RMP.E.1', datapoint:'D1.RM.RMP.E.1 - The risk management program incorporates cyber risk identification, measurement, mitigation, monitoring, and reporting. '}
        , { id:'D1.RM.RMP.E.2', datapoint:'D1.RM.RMP.E.2 - Management reviews and uses the results of audits to improve existing cybersecurity policies, procedures, and controls. '}
        , { id:'D1.RM.RMP.E.3', datapoint:'D1.RM.RMP.E.3 - Management monitors moderate and high residual risk issues from the cybersecurity risk assessment until items are addressed. '}
        , { id:'D1.RM.RMP.Int.1', datapoint:'D1.RM.RMP.Int.1 - The cybersecurity function has a clear reporting line that does not present a conflict of interest. '}
        , { id:'D1.RM.RMP.Int.2', datapoint:'D1.RM.RMP.Int.2 - The risk management program specifically addresses cyber risks beyond the boundaries of the technological impacts (e.g., financial, strategic, regulatory, compliance). '}
        , { id:'D1.RM.RMP.Int.3', datapoint:'D1.RM.RMP.Int.3 - Benchmarks or target performance metrics have been established for showing improvements or regressions of the security posture over time. '}
        , { id:'D1.RM.RMP.Int.4', datapoint:'D1.RM.RMP.Int.4 - Management uses the results of independent audits and reviews to improve cybersecurity. '}
        , { id:'D1.RM.RMP.Int.5', datapoint:'D1.RM.RMP.Int.5 - There is a process to analyze and assign potential losses and related expenses, by cost center, associated with cybersecurity incidents. '}
        , { id:'D1.RM.RMP.A.1', datapoint:'D1.RM.RMP.A.1 - Cybersecurity metrics are used to facilitate strategic decision-making and funding in areas of need. '}
        , { id:'D1.RM.RMP.A.2', datapoint:'D1.RM.RMP.A.2 - Independent risk management sets and monitors cyber-related risk limits for business units. '}
        , { id:'D1.RM.RMP.A.3', datapoint:'D1.RM.RMP.A.3 - Independent risk management staff escalates to management and the board or an appropriate board committee significant discrepancies from business unit’s assessments of cyber-related risk. '}
        , { id:'D1.RM.RMP.A.4', datapoint:'D1.RM.RMP.A.4 - A process is in place to analyze the financial impact cyber incidents have on the institution’s capital. '}
        , { id:'D1.RM.RMP.A.5', datapoint:'D1.RM.RMP.A.5 - The cyber risk data aggregation and real-time reporting capabilities support the institution’s ongoing reporting needs, particularly during cyber incidents.  '}
        , { id:'D1.RM.RMP.Inn.1', datapoint:'D1.RM.RMP.Inn.1 - The risk management function identifies and analyzes commonalities in cyber events that occur both at the institution and across other sectors to enable more predictive risk management. '}
        , { id:'D1.RM.RMP.Inn.2', datapoint:'D1.RM.RMP.Inn.2 - A process is in place to analyze the financial impact that a cyber incident at the institution may have across the financial sector. '}
        , { id:'D1.RM.RA.B.1', datapoint:'D1.RM.RA.B.1 - A risk assessment focused on safeguarding customer information identifies reasonable and foreseeable internal and external threats, the likelihood and potential damage of threats, and the sufficiency of policies, procedures, and customer information syste'}
        , { id:'D1.RM.RA.B.2', datapoint:'D1.RM.RA.B.2 - The risk assessment identifies internet-based systems and high-risk transactions that warrant additional authentication controls. (FFIEC Information Security Booklet, page 12) '}
        , { id:'D1.RM.RA.B.3', datapoint:'D1.RM.RA.B.3 - The risk assessment is updated to address new technologies, products, services, and connections before deployment. (FFIEC Information Security Booklet, page 13) '}
        , { id:'D1.RM.RA.E.1', datapoint:'D1.RM.RA.E.1 - Risk assessments are used to identify the cybersecurity risks stemming from new products, services, or relationships. '}
        , { id:'D1.RM.RA.E.2', datapoint:'D1.RM.RA.E.2 - The focus of the risk assessment has expanded beyond customer information to address all information assets. '}
        , { id:'D1.RM.RA.E.3', datapoint:'D1.RM.RA.E.3 - The risk assessment considers the risk of using EOL software and hardware components.'}
        , { id:'D1.RM.RA.Int.1', datapoint:'D1.RM.RA.Int.1 - The risk assessment is adjusted to consider widely known risks or risk management practices.'}
        , { id:'D1.RM.RA.A.1', datapoint:'D1.RM.RA.A.1 - An enterprise-wide risk management function incorporates cyber threat analysis and specific risk exposure as part of the enterprise risk assessment'}
        , { id:'D1.RM.RA.Inn.1', datapoint:'D1.RM.RA.Inn.1 - The risk assessment is updated in real time as changes to the risk profile occur, new applicable standards are released or updated, and new exposures are anticipated. '}
        , { id:'D1.RM.RA.Inn.2', datapoint:'D1.RM.RA.Inn.2 - The institution uses information from risk assessments to predict threats and drive real-time responses. '}
        , { id:'D1.RM.RA.Inn.3', datapoint:'D1.RM.RA.Inn.3 - Advanced or automated analytics offer predictive information and realtime risk metrics. '}
        , { id:'D1.RM.Au.B.1', datapoint:'D1.RM.Au.B.1 - Independent audit or review evaluates policies, procedures, and controls across the institution for significant risks and control issues associated with the institution’s operations, including risks in new products, emerging technologies, and information '}
        , { id:'D1.RM.Au.B.2', datapoint:'D1.RM.Au.B.2 - The independent audit function validates controls related to the storage or transmission of confidential data. (FFIEC Audit Booklet, page 1) '}
        , { id:'D1.RM.Au.B.3', datapoint:'D1.RM.Au.B.3 - Logging practices are independently reviewed periodically to ensure appropriate log management (e.g., access controls, retention, and maintenance). (FFIEC Operations Booklet, page 29) '}
        , { id:'D1.RM.Au.B.4', datapoint:'D1.RM.Au.B.4 - Issues and corrective actions from internal audits and independent testing/assessments are formally tracked to ensure procedures and control lapses are resolved in a timely manner. (FFIEC Information Security Booklet, page 6)'}
        , { id:'D1.RM.Au.E.1', datapoint:'D1.RM.Au.E.1 - The independent audit function validates that the risk management function is commensurate with the institution’s risk and complexity.  '}
        , { id:'D1.RM.Au.E.2', datapoint:'D1.RM.Au.E.2 - The independent audit function validates that the institution’s threat information sharing is commensurate with the institution’s risk and complexity.  '}
        , { id:'D1.RM.Au.E.3', datapoint:'D1.RM.Au.E.3 - The independent audit function validates that the institution’s cybersecurity controls function is commensurate with the institution’s risk and complexity. '}
        , { id:'D1.RM.Au.E.4', datapoint:'D1.RM.Au.E.4 - The independent audit function validates that the institution’s third-party relationship management is commensurate with the institution’s risk and complexity.  '}
        , { id:'D1.RM.Au.E.5', datapoint:'D1.RM.Au.E.5 - The independent audit function validates that the institution’s incident response program and resilience are commensurate with the institution’s risk and complexity.'}
        , { id:'D1.RM.Au.Int.1', datapoint:'D1.RM.Au.Int.1 - A formal process is in place for the independent audit function to update its procedures based on changes to the institution’s inherent risk profile.  '}
        , { id:'D1.RM.Au.Int.2', datapoint:'D1.RM.Au.Int.2 - The independent audit function validates that the institution’s threat intelligence and collaboration are commensurate with the institution’s risk and complexity.  '}
        , { id:'D1.RM.Au.Int.3', datapoint:'D1.RM.Au.Int.3 - The independent audit function regularly reviews management’s cyber risk appetite statement.  '}
        , { id:'D1.RM.Au.Int.4', datapoint:'D1.RM.Au.Int.4 - Independent audits or reviews are used to identify gaps in existing security capabilities and expertise. '}
        , { id:'D1.RM.Au.A.1', datapoint:'D1.RM.Au.A.1 - A formal process is in place for the independent audit function to update its procedures based on changes to the evolving threat landscape across the sector.  '}
        , { id:'D1.RM.Au.A.2', datapoint:'D1.RM.Au.A.2 - The independent audit function regularly reviews the institution’s cyber risk appetite statement in comparison to assessment results and incorporates gaps into the audit strategy. '}
        , { id:'D1.RM.Au.A.3', datapoint:'D1.RM.Au.A.3 - Independent audits or reviews are used to identify cybersecurity weaknesses, root causes, and the potential impact to business units. '}
        , { id:'D1.RM.Au.Inn.1', datapoint:'D1.RM.Au.Inn.1 - A formal process is in place for the independent audit function to update its procedures based on changes to the evolving threat landscape across other sectors the institution depends upon.  '}
        , { id:'D1.RM.Au.Inn.2', datapoint:'D1.RM.Au.Inn.2 - The independent audit function uses sophisticated data mining tools to perform continuous monitoring of cybersecurity processes or controls. '}
        , { id:'D1.R.St.B.1', datapoint:'D1.R.St.B.1 - Information security roles and responsibilities have been identified. (FFIEC Information Security Booklet, page 7) '}
        , { id:'D1.R.St.B.2', datapoint:'D1.R.St.B.2 - Processes are in place to identify additional expertise needed to improve information security defenses. (FFIEC Information Security Work Program, Objective I: 2-8)'}
        , { id:'D1.R.St.E.1', datapoint:'D1.R.St.E.1 - A formal process is used to identify cybersecurity tools and expertise that may be needed. '}
        , { id:'D1.R.St.E.2', datapoint:'D1.R.St.E.2 - Management with appropriate knowledge and experience leads the institution’s cybersecurity efforts. '}
        , { id:'D1.R.St.E.3', datapoint:'D1.R.St.E.3 - Staff with cybersecurity responsibilities have the requisite qualifications to perform the necessary tasks of the position. '}
        , { id:'D1.R.St.E.4', datapoint:'D1.R.St.E.4 - Employment candidates, contractors, and third parties are subject to background verification proportional to the confidentiality of the data accessed, business requirements, and acceptable risk. '}
        , { id:'D1.R.St.Int.1', datapoint:'D1.R.St.Int.1 - The institution has a program for talent recruitment, retention, and succession planning for the cybersecurity and resilience staffs.'}
        , { id:'D1.R.St.A.1', datapoint:'D1.R.St.A.1 - The institution benchmarks its cybersecurity staffing against peers to identify whether its recruitment, retention, and succession planning are commensurate.  '}
        , { id:'D1.R.St.A.2', datapoint:'D1.R.St.A.2 - Dedicated cybersecurity staff develops, or contributes to developing, integrated enterprise-level security and cyber defense strategies.  '}
        , { id:'D1.R.St.Inn.1', datapoint:'D1.R.St.Inn.1 - The institution actively partners with industry associations and academia to inform curricula based on future cybersecurity staffing needs of the industry. '}
        , { id:'D1.TC.Tr.B.1', datapoint:'D1.TC.Tr.B.1 - Annual information security training is provided. (FFIEC Information Security Booklet, page 66) '}
        , { id:'D1.TC.Tr.B.2', datapoint:'D1.TC.Tr.B.2 - Annual information security training includes incident response, current cyber threats (e.g., phishing, spear phishing, social engineering, and mobile security), and emerging issues. (FFIEC Information Security Booklet, page 66) '}
        , { id:'D1.TC.Tr.B.3', datapoint:'D1.TC.Tr.B.3 - Situational awareness materials are made available to employees when prompted by highly visible cyber events or by regulatory alerts. (FFIEC Information Security Booklet, page 7) '}
        , { id:'D1.TC.Tr.B.4', datapoint:'D1.TC.Tr.B.4 - Customer awareness materials are readily available (e.g., DHS’ Cybersecurity Awareness Month materials). (FFIEC E-Banking Work Program, Objective 6-3)'}
        , { id:'D1.TC.Tr.E.1', datapoint:'D1.TC.Tr.E.1 - The institution has a program for continuing cybersecurity training and skill development for cybersecurity staff. '}
        , { id:'D1.TC.Tr.E.2', datapoint:'D1.TC.Tr.E.2 - Management is provided cybersecurity training relevant to their job responsibilities. '}
        , { id:'D1.TC.Tr.E.3', datapoint:'D1.TC.Tr.E.3 - Employees with privileged account permissions receive additional cybersecurity training commensurate with their levels of responsibility. '}
        , { id:'D1.TC.Tr.E.4', datapoint:'D1.TC.Tr.E.4 - Business units are provided cybersecurity training relevant to their particular business risks. '}
        , { id:'D1.TC.Tr.E.5', datapoint:'D1.TC.Tr.E.5 - The institution validates the effectiveness of training (e.g., social engineering or phishing tests). '}
        , { id:'D1.TC.Tr.Int.1', datapoint:'D1.TC.Tr.Int.1 - Management incorporates lessons learned from social engineering and phishing exercises to improve the employee awareness programs. '}
        , { id:'D1.TC.Tr.Int.2', datapoint:'D1.TC.Tr.Int.2 - Cybersecurity awareness information is provided to retail customers and commercial clients at least annually. '}
        , { id:'D1.TC.Tr.Int.3', datapoint:'D1.TC.Tr.Int.3 - Business units are provided cybersecurity training relevant to their particular business risks, over and above what is required of the institution as a whole. '}
        , { id:'D1.TC.Tr.Int.4', datapoint:'D1.TC.Tr.Int.4 - The institution routinely updates its training to security staff to adapt to new threats. '}
        , { id:'D1.TC.Tr.A.1', datapoint:'D1.TC.Tr.A.1 - Independent directors are provided with cybersecurity training that addresses how complex products, services, and lines of business affect the institution’s cyber risk.'}
        , { id:'D1.TC.Tr.Inn.1', datapoint:'D1.TC.Tr.Inn.1 - Key performance indicators are used to determine whether training and awareness programs positively influence behavior.'}
        , { id:'D1.TC.Cu.B.1', datapoint:'D1.TC.Cu.B.1 - Management holds employees accountable for complying with the information security program. (FFIEC Information Security Booklet, page 7) '}
        , { id:'D1.TC.Cu.E.1', datapoint:'D1.TC.Cu.E.1 - The institution has formal standards of conduct that hold all employees accountable for complying with cybersecurity policies and procedures. '}
        , { id:'D1.TC.Cu.E.2', datapoint:'D1.TC.Cu.E.2 - Cyber risks are actively discussed at business unit meetings. '}
        , { id:'D1.TC.Cu.E.3', datapoint:'D1.TC.Cu.E.3 - Employees have a clear understanding of how to identify and escalate potential cybersecurity issues'}
        , { id:'D1.TC.Cu.Int.1', datapoint:'D1.TC.Cu.Int.1 - Management ensures performance plans are tied to compliance with cybersecurity policies and standards in order to hold employees accountable. '}
        , { id:'D1.TC.Cu.Int.2', datapoint:'D1.TC.Cu.Int.2 - The risk culture requires formal consideration of cyber risks in all business decisions. '}
        , { id:'D1.TC.Cu.Int.3', datapoint:'D1.TC.Cu.Int.3 - Cyber risk reporting is presented and discussed at the independent risk management meetings.'}
        , { id:'D1.TC.Cu.A.1', datapoint:'D1.TC.Cu.A.1 - Management ensures continuous improvement of cyber risk cultural awareness'}
        , { id:'D1.TC.Cu.Inn.1', datapoint:'D1.TC.Cu.Inn.1 - The institution leads efforts to promote cybersecurity culture across the sector and to other sectors that they depend upon. '}
        , { id:'D2.TI.Ti.B.1', datapoint:'D2.TI.Ti.B.1 - The institution belongs or subscribes to a threat and vulnerability information sharing source(s) that provides information on threats (e.g., Financial Services Information Sharing and Analysis Center [FS-ISAC], U.S. Computer Emergency Readiness Team [US-'}
        , { id:'D2.TI.Ti.B.2', datapoint:'D2.TI.Ti.B.2 - Threat information is used to monitor threats and vulnerabilities. (FFIEC Information Security Booklet, page 83) '}
        , { id:'D2.TI.Ti.B.3', datapoint:'D2.TI.Ti.B.3 - Threat information is used to enhance internal risk management and controls. (FFIEC Information Security Booklet, page 4)'}
        , { id:'D2.TI.Ti.E.1', datapoint:'D2.TI.Ti.E.1 - Threat information received by the institution includes analysis of tactics, patterns, and risk mitigation recommendations'}
        , { id:'D2.TI.Ti.Int.1', datapoint:'D2.TI.Ti.Int.1 - A formal threat intelligence program is implemented and includes subscription to threat feeds from external providers and internal sources. '}
        , { id:'D2.TI.Ti.Int.2', datapoint:'D2.TI.Ti.Int.2 - Protocols are implemented for collecting information from industry peers and government. '}
        , { id:'D2.TI.Ti.Int.3', datapoint:'D2.TI.Ti.Int.3 - A read-only, central repository of cyber threat intelligence is maintained.'}
        , { id:'D2.TI.Ti.A.1', datapoint:'D2.TI.Ti.A.1 - A cyber intelligence model is used for gathering threat information. '}
        , { id:'D2.TI.Ti.A.2', datapoint:'D2.TI.Ti.A.2 - Threat intelligence is automatically received from multiple sources in real time. '}
        , { id:'D2.TI.Ti.A.3', datapoint:'D2.TI.Ti.A.3 - The institution’s threat intelligence includes information related to geopolitical events that could increase cybersecurity threat levels. '}
        , { id:'D2.TI.Ti.Inn.1', datapoint:'D2.TI.Ti.Inn.1 - A threat analysis system automatically correlates threat data to specific risks and then takes risk-based automated actions while alerting management. '}
        , { id:'D2.TI.Ti.Inn.2', datapoint:'D2.TI.Ti.Inn.2 - The institution is investing in the development of new threat intelligence and collaboration mechanisms (e.g., technologies, business processes) that will transform how information is gathered and shared. '}
        , { id:'D2.MA.Ma.B.1', datapoint:'D2.MA.Ma.B.1 - Audit log records and other security event logs are reviewed and retained in a secure manner. (FFIEC Information Security Booklet, page 79) '}
        , { id:'D2.MA.Ma.B.2', datapoint:'D2.MA.Ma.B.2 - Computer event logs are used for investigations once an event has occurred. (FFIEC Information Security Booklet, page 83'}
        , { id:'D2.MA.Ma.E.1', datapoint:'D2.MA.Ma.E.1 - A process is implemented to monitor threat information to discover emerging threats. '}
        , { id:'D2.MA.Ma.E.2', datapoint:'D2.MA.Ma.E.2 - The threat information and analysis process is assigned to a specific group or individual. '}
        , { id:'D2.MA.Ma.E.3', datapoint:'D2.MA.Ma.E.3 - Security processes and technology are centralized and coordinated in a Security Operations Center (SOC) or equivalent. '}
        , { id:'D2.MA.Ma.E.4', datapoint:'D2.MA.Ma.E.4 - Monitoring systems operate continuously with adequate support for efficient incident handling. '}
        , { id:'D2.MA.Ma.Int.1', datapoint:'D2.MA.Ma.Int.1 - A threat intelligence team is in place that evaluates threat intelligence from multiple sources for credibility, relevance, and exposure. '}
        , { id:'D2.MA.Ma.Int.2', datapoint:'D2.MA.Ma.Int.2 - A profile is created for each threat that identifies the likely intent, capability, and target of the threat. '}
        , { id:'D2.MA.Ma.Int.3', datapoint:'D2.MA.Ma.Int.3 - Threat information sources that address all components of the threat profile are prioritized and monitored. '}
        , { id:'D2.MA.Ma.Int.4', datapoint:'D2.MA.Ma.Int.4 - Threat intelligence is analyzed to develop cyber threat summaries including risks to the institution and specific actions for the institution to consider. '}
        , { id:'D2.MA.Ma.A.1', datapoint:'D2.MA.Ma.A.1 - A dedicated cyber threat identification and analysis committee or team exists to centralize and coordinate initiatives and communications. '}
        , { id:'D2.MA.Ma.A.2', datapoint:'D2.MA.Ma.A.2 - Formal processes have been defined to resolve potential conflicts in information received from sharing and analysis centers or other sources. '}
        , { id:'D2.MA.Ma.A.3', datapoint:'D2.MA.Ma.A.3 - Emerging internal and external threat intelligence and correlated log analysis are used to predict future attacks. '}
        , { id:'D2.MA.Ma.A.4', datapoint:'D2.MA.Ma.A.4 - Threat intelligence is viewed within the context of the institution’s risk profile and risk appetite to prioritize mitigating actions in anticipation of threats. '}
        , { id:'D2.MA.Ma.A.5', datapoint:'D2.MA.Ma.A.5 - Threat intelligence is used to update architecture and configuration standards. '}
        , { id:'D2.MA.Ma.Inn.1', datapoint:'D2.MA.Ma.Inn.1 - The institution uses multiple sources of intelligence, correlated log analysis, alerts, internal traffic flows, and geopolitical events to predict potential future attacks and attack trends. '}
        , { id:'D2.MA.Ma.Inn.2', datapoint:'D2.MA.Ma.Inn.2 - Highest risk scenarios are used to predict threats against specific business targets. '}
        , { id:'D2.MA.Ma.Inn.3', datapoint:'D2.MA.Ma.Inn.3 - IT systems automatically detect configuration weaknesses based on threat intelligence and alert management so actions can be prioritized. '}
        , { id:'D2.IS.Is.B.1', datapoint:'D2.IS.Is.B.1 - Information security threats are gathered and shared with applicable internal employees. (FFIEC Information Security Booklet, page 83) '}
        , { id:'D2.IS.Is.B.2', datapoint:'D2.IS.Is.B.2 - Contact information for law enforcement and the regulator(s) is maintained and updated regularly. (FFIEC Business Continuity Planning Work Program, Objective I: 5-1) '}
        , { id:'D2.IS.Is.B.3', datapoint:'D2.IS.Is.B.3 - Information about threats is shared with law enforcement and regulators when required or prompted. (FFIEC Information Security Booklet, page 84) '}
        , { id:'D2.IS.Is.E.1', datapoint:'D2.IS.Is.E.1 - A formal and secure process is in place to share threat and vulnerability information with other entities. '}
        , { id:'D2.IS.Is.E.2', datapoint:'D2.IS.Is.E.2 - A representative from the institution participates in law enforcement or information-sharing organization meetings.'}
        , { id:'D2.IS.Is.Int.1', datapoint:'D2.IS.Is.Int.1 - A formal protocol is in place for sharing threat, vulnerability, and incident information to employees based on their specific job function. '}
        , { id:'D2.IS.Is.Int.2', datapoint:'D2.IS.Is.Int.2 - Information-sharing agreements are used as needed or required to facilitate sharing threat information with other financial sector organizations or third parties. '}
        , { id:'D2.IS.Is.Int.3', datapoint:'D2.IS.Is.Int.3 - Information is shared proactively with the industry, law enforcement, regulators, and information-sharing forums. '}
        , { id:'D2.IS.Is.Int.4', datapoint:'D2.IS.Is.Int.4 - A process is in place to communicate and collaborate with the public sector regarding cyber threats. '}
        , { id:'D2.IS.Is.A.1', datapoint:'D2.IS.Is.A.1 - Management communicates threat intelligence with business risk context and specific risk management recommendations to the business units. '}
        , { id:'D2.IS.Is.A.2', datapoint:'D2.IS.Is.A.2 - Relationships exist with employees of peer institutions for sharing cyber threat intelligence. '}
        , { id:'D2.IS.Is.A.3', datapoint:'D2.IS.Is.A.3 - A network of trust relationships (formal and/or informal) has been established to evaluate information about cyber threats. '}
        , { id:'D2.IS.Is.Inn.1', datapoint:'D2.IS.Is.Inn.1 - A mechanism is in place for sharing cyber threat intelligence with business units in real time including the potential financial and operational impact of inaction. '}
        , { id:'D2.IS.Is.Inn.2', datapoint:'D2.IS.Is.Inn.2 - A system automatically informs management of the level of business risk specific to the institution and the progress of recommended steps taken to mitigate the risks. '}
        , { id:'D2.IS.Is.Inn.3', datapoint:'D2.IS.Is.Inn.3 - The institution is leading efforts to create new sector-wide informationsharing channels to address gaps in external-facing information-sharing mechanisms. '}
        , { id:'D3.PC.Im.B.1', datapoint:'D3.PC.Im.B.1 - Network perimeter defense tools (e.g., border router and firewall) are used. (FFIEC Information Security Booklet, page 33) '}
        , { id:'D3.PC.Im.B.2', datapoint:'D3.PC.Im.B.2 - Systems that are accessed from the Internet or by external parties are protected by firewalls or other similar devices. (FFIEC Information Security Booklet, page 46) '}
        , { id:'D3.PC.Im.B.3', datapoint:'D3.PC.Im.B.3 - All ports are monitored. (FFIEC Information Security Booklet, page 50) '}
        , { id:'D3.PC.Im.B.4', datapoint:'D3.PC.Im.B.4 - Up to date antivirus and anti-malware tools are used. (FFIEC Information Security Booklet, page 78) '}
        , { id:'D3.PC.Im.B.5', datapoint:'D3.PC.Im.B.5 - Systems configurations (for servers, desktops, routers, etc.) follow industry standards and are enforced. (FFIEC Information Security Booklet, page 56) '}
        , { id:'D3.PC.Im.B.6', datapoint:'D3.PC.Im.B.6 - Ports, functions, protocols and services are prohibited if no longer needed for business purposes. (FFIEC Information Security Booklet, page 50) '}
        , { id:'D3.PC.Im.B.7', datapoint:'D3.PC.Im.B.7 - Access to make changes to systems configurations (including virtual machines and hypervisors) is controlled and monitored. (FFIEC Information Security Booklet, page 56) '}
        , { id:'D3.PC.Im.B.8', datapoint:'D3.PC.Im.B.8 - Programs that can override system, object, network, virtual machine, and application controls are restricted. (FFIEC Information Security Booklet, page 41) '}
        , { id:'D3.PC.Im.B.9', datapoint:'D3.PC.Im.B.9 - System sessions are locked after a pre-defined period of inactivity and are terminated after pre-defined conditions are met. (FFIEC Information Security Booklet, page 23) '}
        , { id:'D3.PC.Im.B.10', datapoint:'D3.PC.Im.B.10 - Wireless network environments require security settings with strong encryption for authentication and transmission. (*N/A if there are no wireless networks.) (FFIEC Information Security Booklet, page 40) '}
        , { id:'D3.PC.Im.E.1', datapoint:'D3.PC.Im.E.1 - There is a firewall at each Internet connection and between any Demilitarized Zone (DMZ) and internal network(s). '}
        , { id:'D3.PC.Im.E.2', datapoint:'D3.PC.Im.E.2 - Antivirus and intrusion detection/prevention systems (IDS/IPS) detect and block actual and attempted attacks or intrusions. '}
        , { id:'D3.PC.Im.E.3', datapoint:'D3.PC.Im.E.3 - Technical controls prevent unauthorized devices, including rogue wireless access devices and removable media, from connecting to the internal network(s). '}
        , { id:'D3.PC.Im.E.4', datapoint:'D3.PC.Im.E.4 - A risk-based solution is in place at the institution or Internet hosting provider to mitigate disruptive cyber attacks (e.g., DDoS attacks). '}
        , { id:'D3.PC.Im.E.5', datapoint:'D3.PC.Im.E.5 - Guest wireless networks are fully segregated from the internal network(s). (*N/A if there are no wireless networks.)  '}
        , { id:'D3.PC.Im.E.6', datapoint:'D3.PC.Im.E.6 - Domain Name System Security Extensions (DNSSEC) is deployed across the enterprise. '}
        , { id:'D3.PC.Im.E.7', datapoint:'D3.PC.Im.E.7 - Critical systems supported by legacy technologies are regularly reviewed to identify for potential vulnerabilities, upgrade opportunities, or new defense layers. '}
        , { id:'D3.PC.Im.E.8', datapoint:'D3.PC.Im.E.8 - Controls for unsupported systems are implemented and tested.'}
        , { id:'D3.PC.Im.Int.1', datapoint:'D3.PC.Im.Int.1 - The enterprise network is segmented in multiple, separate trust/security zones with defense-in-depth strategies (e.g., logical network segmentation, hard backups, air-gapping) to mitigate attacks. '}
        , { id:'D3.PC.Im.Int.2', datapoint:'D3.PC.Im.Int.2 - Security controls are used for remote access to all administrative consoles, including restricted virtual systems.  '}
        , { id:'D3.PC.Im.Int.3', datapoint:'D3.PC.Im.Int.3 - Wireless network environments have perimeter firewalls that are implemented and configured to restrict unauthorized traffic. (*N/A if there are no wireless networks.) '}
        , { id:'D3.PC.Im.Int.4', datapoint:'D3.PC.Im.Int.4 - Wireless networks use strong encryption with encryption keys that are changed frequently. (*N/A if there are no wireless networks.) '}
        , { id:'D3.PC.Im.Int.5', datapoint:'D3.PC.Im.Int.5 - The broadcast range of the wireless network(s) is confined to institutioncontrolled boundaries. (*N/A if there are no wireless networks.) '}
        , { id:'D3.PC.Im.Int.6', datapoint:'D3.PC.Im.Int.6 - Technical measures are in place to prevent the execution of unauthorized code on institution owned or managed devices, network infrastructure, and systems components. '}
        , { id:'D3.PC.Im.A.1', datapoint:'D3.PC.Im.A.1 - Network environments and virtual instances are designed and configured to restrict and monitor traffic between trusted and untrusted zones. '}
        , { id:'D3.PC.Im.A.2', datapoint:'D3.PC.Im.A.2 - Only one primary function is permitted per server to prevent functions that require different security levels from co-existing on the same server. '}
        , { id:'D3.PC.Im.A.3', datapoint:'D3.PC.Im.A.3 - Anti-spoofing measures are in place to detect and block forged source IP addresses from entering the network.  '}
        , { id:'D3.PC.Im.Inn.1', datapoint:'D3.PC.Im.Inn.1 - The institution risk scores all of its infrastructure assets and updates in real time based on threats, vulnerabilities, or operational changes. '}
        , { id:'D3.PC.Im.Inn.2', datapoint:'D3.PC.Im.Inn.2 - Automated controls are put in place based on risk scores to infrastructure assets, including automatically disconnecting affected assets.  '}
        , { id:'D3.PC.Im.Inn.3', datapoint:'D3.PC.Im.Inn.3 - The institution proactively seeks to identify control gaps that may be used as part of a zero-day attack. '}
        , { id:'D3.PC.Im.Inn.4', datapoint:'D3.PC.Im.Inn.4 - Public-facing servers are routinely rotated and restored to a known clean state to limit the window of time a system is exposed to potential threats. '}
        , { id:'D3.PC.Am.B.1', datapoint:'D3.PC.Am.B.1 - Employee access is granted to systems and confidential data based on job responsibilities and the principles of least privilege. (FFIEC Information Security Booklet, page 19) '}
        , { id:'D3.PC.Am.B.2', datapoint:'D3.PC.Am.B.2 - Employee access to systems and confidential data provides for separation of duties. (FFIEC Information Security Booklet, page 19) '}
        , { id:'D3.PC.Am.B.3', datapoint:'D3.PC.Am.B.3 - Elevated privileges (e.g., administrator privileges) are limited and tightly controlled (e.g., assigned to individuals, not shared, and require stronger password controls). (FFIEC Information Security Booklet, page 19) '}
        , { id:'D3.PC.Am.B.4', datapoint:'D3.PC.Am.B.4 - User access reviews are performed periodically for all systems and applications based on the risk to the application or system. (FFIEC Information Security Booklet, page 18) '}
        , { id:'D3.PC.Am.B.5', datapoint:'D3.PC.Am.B.5 - Changes to physical and logical user access, including those that result from voluntary and involuntary terminations, are submitted to and approved by appropriate personnel. (FFIEC Information Security Booklet, page 18) '}
        , { id:'D3.PC.Am.B.6', datapoint:'D3.PC.Am.B.6 - Identification and authentication are required and managed for access to systems, applications, and hardware. (FFIEC Information Security Booklet, page 21) '}
        , { id:'D3.PC.Am.B.7', datapoint:'D3.PC.Am.B.7 - Access controls include password complexity and limits to password attempts and reuse. (FFIEC Information Security Booklet, page 66) '}
        , { id:'D3.PC.Am.B.8', datapoint:'D3.PC.Am.B.8 - All default passwords and unnecessary default accounts are changed before system implementation. (FFIEC Information Security Booklet, page 61)  '}
        , { id:'D3.PC.Am.B.9', datapoint:'D3.PC.Am.B.9 - Customer access to Internet-based products or services requires authentication controls (e.g., layered controls, multifactor) that are commensurate with the risk. (FFIEC Information Security Booklet, page 21) '}
        , { id:'D3.PC.Am.B.10', datapoint:'D3.PC.Am.B.10 - Production and non-production environments are segregated to prevent unauthorized access or changes to information assets. (*N/A if no production environment exists at the institution or the institution’s third party.) (FFIEC Information Security Booklet,'}
        , { id:'D3.PC.Am.B.11', datapoint:'D3.PC.Am.B.11 - Physical security controls are used to prevent unauthorized access to information systems and telecommunication systems. (FFIEC Information Security Booklet, page 47) '}
        , { id:'D3.PC.Am.B.12', datapoint:'D3.PC.Am.B.12 - All passwords are encrypted in storage and in transit. (FFIEC Information Security Booklet, page 21)  '}
        , { id:'D3.PC.Am.B.13', datapoint:'D3.PC.Am.B.13 - Confidential data are encrypted when transmitted across public or untrusted networks (e.g., Internet). (FFIEC Information Security Booklet, page 51) '}
        , { id:'D3.PC.Am.B.14', datapoint:'D3.PC.Am.B.14 - Mobile devices (e.g., laptops, tablets, and removable media) are encrypted if used to store confidential data. (*N/A if mobile devices are not used.) (FFIEC Information Security Booklet, page 51) '}
        , { id:'D3.PC.Am.B.15', datapoint:'D3.PC.Am.B.15 - Remote access to critical systems by employees, contractors, and third parties uses encrypted connections and multifactor authentication. (FFIEC Information Security Booklet, page 45) '}
        , { id:'D3.PC.Am.B.16', datapoint:'D3.PC.Am.B.16 - Administrative, physical, or technical controls are in place to prevent users without administrative responsibilities from installing unauthorized software. (FFIEC Information Security Booklet, page 25) '}
        , { id:'D3.PC.Am.B.17', datapoint:'D3.PC.Am.B.17 - Customer service (e.g., the call center) utilizes formal procedures to authenticate customers commensurate with the risk of the transaction or request. (FFIEC Information Security Booklet, page 19) '}
        , { id:'D3.PC.Am.B.18', datapoint:'D3.PC.Am.B.18 - Data is disposed of or destroyed according to documented requirements and within expected time frames. (FFIEC Information Security Booklet, page 66) '}
        , { id:'D3.PC.Am.E.1', datapoint:'D3.PC.Am.E.1 - Changes to user access permissions trigger automated notices to appropriate personnel. '}
        , { id:'D3.PC.Am.E.2', datapoint:'D3.PC.Am.E.2 - Administrators have two accounts: one for administrative use and one for general purpose, non-administrative tasks. '}
        , { id:'D3.PC.Am.E.3', datapoint:'D3.PC.Am.E.3 - Use of customer data in non-production environments complies with legal, regulatory, and internal policy requirements for concealing or removing of sensitive data elements. '}
        , { id:'D3.PC.Am.E.4', datapoint:'D3.PC.Am.E.4 - Physical access to high-risk or confidential systems is restricted, logged, and unauthorized access is blocked. '}
        , { id:'D3.PC.Am.E.5', datapoint:'D3.PC.Am.E.5 - Controls are in place to prevent unauthorized access to cryptographic keys. '}
        , { id:'D3.PC.Am.Int.1', datapoint:'D3.PC.Am.Int.1 - The institution has implemented tools to prevent unauthorized access to or exfiltration of confidential data. '}
        , { id:'D3.PC.Am.Int.2', datapoint:'D3.PC.Am.Int.2 - Controls are in place to prevent unauthorized escalation of user privileges. '}
        , { id:'D3.PC.Am.Int.3', datapoint:'D3.PC.Am.Int.3 - Access controls are in place for database administrators to prevent unauthorized downloading or transmission of confidential data. '}
        , { id:'D3.PC.Am.Int.4', datapoint:'D3.PC.Am.Int.4 - All physical and logical access is removed immediately upon notification of involuntary termination and within 24 hours of an employee’s voluntary departure. '}
        , { id:'D3.PC.Am.Int.5', datapoint:'D3.PC.Am.Int.5 - Multifactor authentication and/or layered controls have been implemented to secure all third-party access to the institution’s network and/or systems and applications. '}
        , { id:'D3.PC.Am.Int.6', datapoint:'D3.PC.Am.Int.6 - Multifactor authentication (e.g., tokens, digital certificates) techniques are used for employee access to high-risk systems as identified in the risk assessment(s). (*N/A if no high risk systems.) '}
        , { id:'D3.PC.Am.Int.7', datapoint:'D3.PC.Am.Int.7 - Confidential data are encrypted in transit across private connections (e.g., frame relay and T1) and within the institution’s trusted zones. '}
        , { id:'D3.PC.Am.Int.8', datapoint:'D3.PC.Am.Int.8 - Controls are in place to prevent unauthorized access to collaborative computing devices and applications (e.g., networked white boards, cameras, microphones, online applications such as instant messaging and document sharing). (* N/A if collaborative comp'}
        , { id:'D3.PC.Am.A.1', datapoint:'D3.PC.Am.A.1 - Encryption of select data at rest is determined by the institution’s data classification and risk assessment. '}
        , { id:'D3.PC.Am.A.2', datapoint:'D3.PC.Am.A.2 - Customer authentication for high-risk transactions includes methods to prevent malware and man-in-the-middle attacks (e.g., using visual transaction signing). '}
        , { id:'D3.PC.Am.Inn.5', datapoint:'D3.PC.Am.Inn.5 - Adaptive access controls de-provision or isolate an employee, third-party, or customer credentials to minimize potential damage if malicious behavior is suspected. '}
        , { id:'D3.PC.Am.Inn.6', datapoint:'D3.PC.Am.Inn.6 - Unstructured confidential data are tracked and secured through an identity-aware, cross-platform storage system that protects against internal threats, monitors user access, and tracks changes. '}
        , { id:'D3.PC.Am.Inn.7', datapoint:'D3.PC.Am.Inn.7 - Tokenization is used to substitute unique values for confidential information (e.g., virtual credit card). '}
        , { id:'D3.PC.Am.Inn.8', datapoint:'D3.PC.Am.Inn.8 - The institution is leading efforts to create new technologies and processes for managing customer, employee, and third-party authentication and access.  '}
        , { id:'D3.PC.Am.Inn.9', datapoint:'D3.PC.Am.Inn.9 - Real-time risk mitigation is taken based on automated risk scoring of user credentials.  '}
        , { id:'D3.PC.De.B.1', datapoint:'D3.PC.De.B.1 - Controls are in place to restrict the use of removable media to authorized personnel. (FFIEC Information Security Work Program, Objective I: 4-1) '}
        , { id:'D3.PC.De.E.1', datapoint:'D3.PC.De.E.1 - Tools automatically block attempted access from unpatched employee and third-party devices. '}
        , { id:'D3.PC.De.E.2', datapoint:'D3.PC.De.E.2 - Tools automatically block attempted access by unregistered devices to internal networks.  '}
        , { id:'D3.PC.De.E.3', datapoint:'D3.PC.De.E.3 - The institution has controls to prevent the unauthorized addition of new connections.  '}
        , { id:'D3.PC.De.E.4', datapoint:'D3.PC.De.E.4 - Controls are in place to prevent unauthorized individuals from copying confidential data to removable media. '}
        , { id:'D3.PC.De.E.5', datapoint:'D3.PC.De.E.5 - Antivirus and anti-malware tools are deployed on end-point devices (e.g., workstations, laptops, and mobile devices). '}
        , { id:'D3.PC.De.E.6', datapoint:'D3.PC.De.E.6 - Mobile devices with access to the institution’s data are centrally managed for antivirus and patch deployment. (*N/A if mobile devices are not used.) '}
        , { id:'D3.PC.De.E.7', datapoint:'D3.PC.De.E.7 - The institution wipes data remotely on mobile devices when a device is missing or stolen. (*N/A if mobile devices are not used.)'}
        , { id:'D3.PC.De.Int.1', datapoint:'D3.PC.De.Int.1 - Data loss prevention controls or devices are implemented for inbound and outbound communications (e.g., e-mail, FTP, Telnet, prevention of large file transfers). '}
        , { id:'D3.PC.De.Int.2', datapoint:'D3.PC.De.Int.2 - Mobile device management includes integrity scanning (e.g., jailbreak/rooted detection). (*N/A if mobile devices are not used.) '}
        , { id:'D3.PC.De.Int.3', datapoint:'D3.PC.De.Int.3 - Mobile devices connecting to the corporate network for storing and accessing company information allow for remote software version/patch validation. (*N/A if mobile devices are not used.) '}
        , { id:'D3.PC.De.A.1', datapoint:'D3.PC.De.A.1 - Employees’ and third parties’ devices (including mobile) without the latest security patches are quarantined and patched before the device is granted access to the network. '}
        , { id:'D3.PC.De.A.2', datapoint:'D3.PC.De.A.2 - Confidential data and applications on mobile devices are only accessible via a secure, isolated sandbox or a secure container. '}
        , { id:'D3.PC.De.Inn.1', datapoint:'D3.PC.De.Inn.1 - A centralized end-point management tool provides fully integrated patch, configuration, and vulnerability management, while also being able to detect malware upon arrival to prevent an exploit. '}
        , { id:'D3.PC.Se.B.1', datapoint:'D3.PC.Se.B.1 - Developers working for the institution follow secure program coding practices, as part of a system development life cycle (SDLC), that meet industry standards. (FFIEC Information Security Booklet, page 56) '}
        , { id:'D3.PC.Se.B.2', datapoint:'D3.PC.Se.B.2 - The security controls of internally developed software are periodically reviewed and tested. (*N/A if there is no software development.) (FFIEC Information Security Booklet, page 59) '}
        , { id:'D3.PC.Se.B.3', datapoint:'D3.PC.Se.B.3 - The security controls in internally developed software code are independently reviewed before migrating the code to production. (*N/A if there is no software development.) (FFIEC Development and Acquisition Booklet, page 2) '}
        , { id:'D3.PC.Se.B.4', datapoint:'D3.PC.Se.B.4 - Intellectual property and production code are held in escrow. (*N/A if there is no production code to hold in escrow.) (FFIEC Development and Acquisition Booklet, page 39)'}
        , { id:'D3.PC.Se.E.1', datapoint:'D3.PC.Se.E.1 - Security testing occurs at all post-design phases of the SDLC for all applications, including mobile applications. (*N/A if there is no software development.) '}
        , { id:'D3.PC.Se.Int.1', datapoint:'D3.PC.Se.Int.1 - Processes are in place to mitigate vulnerabilities identified as part of the secure development of systems and applications. '}
        , { id:'D3.PC.Se.Int.2', datapoint:'D3.PC.Se.Int.2 - The security of applications, including Web-based applications connected to the Internet, is tested against known types of cyber attacks (e.g., SQL injection, cross-site scripting, buffer overflow) before implementation or following significant changes. '}
        , { id:'D3.PC.Se.Int.3', datapoint:'D3.PC.Se.Int.3 - Software code executables and scripts are digitally signed to confirm the software author and guarantee that the code has not been altered or corrupted. '}
        , { id:'D3.PC.Se.Int.4', datapoint:'D3.PC.Se.Int.4 - A risk-based, independent information assurance function evaluates the security of internal applications.'}
        , { id:'D3.PC.Se.A.1', datapoint:'D3.PC.Se.A.1 - Vulnerabilities identified through a static code analysis are remediated before implementing newly developed or changed applications into production. '}
        , { id:'D3.PC.Se.A.2', datapoint:'D3.PC.Se.A.2 - All interdependencies between applications and services have been identified'}
        , { id:'D3.PC.Se.A.3', datapoint:'D3.PC.Se.A.3 - Independent code reviews are completed on internally developed or vendor-provided custom applications to ensure there are no security gaps.'}
        , { id:'D3.PC.Se.Inn.1', datapoint:'D3.PC.Se.Inn.1 - Software code is actively scanned by automated tools in the development environment so that security weaknesses can be resolved immediately during the design phase. '}
        , { id:'D3.DC.Th.B.1', datapoint:'D3.DC.Th.B.1 - Independent testing (including penetration testing and vulnerability scanning) is conducted according to the risk assessment for externalfacing systems and the internal network. (FFIEC Information Security Booklet, page 61)  '}
        , { id:'D3.DC.Th.B.2', datapoint:'D3.DC.Th.B.2 - Antivirus and anti-malware tools are used to detect attacks. (FFIEC Information Security Booklet, page 55) '}
        , { id:'D3.DC.Th.B.3', datapoint:'D3.DC.Th.B.3 - Firewall rules are audited or verified at least quarterly. (FFIEC Information Security Booklet, page 82) '}
        , { id:'D3.DC.Th.B.4', datapoint:'D3.DC.Th.B.4 - E-mail protection mechanisms are used to filter for common cyber threats (e.g., attached malware or malicious links). (FFIEC Information Security Booklet, page 39) '}
        , { id:'D3.DC.Th.E.1', datapoint:'D3.DC.Th.E.1 - Independent penetration testing of network boundary and critical Webfacing applications is performed routinely to identify security control gaps. '}
        , { id:'D3.DC.Th.E.2', datapoint:'D3.DC.Th.E.2 - Independent penetration testing is performed on Internet-facing applications or systems before they are launched or undergo significant change. '}
        , { id:'D3.DC.Th.E.3', datapoint:'D3.DC.Th.E.3 - Antivirus and anti-malware tools are updated automatically. '}
        , { id:'D3.DC.Th.E.4', datapoint:'D3.DC.Th.E.4 - Firewall rules are updated routinely.  '}
        , { id:'D3.DC.Th.E.5', datapoint:'D3.DC.Th.E.5 - Vulnerability scanning is conducted and analyzed before deployment/redeployment of new/existing devices. '}
        , { id:'D3.DC.Th.E.6', datapoint:'D3.DC.Th.E.6 - Processes are in place to monitor potential insider activity that could lead to data theft or destruction.'}
        , { id:'D3.DC.Th.Int.1', datapoint:'D3.DC.Th.Int.1 - Audit or risk management resources review the penetration testing scope and results to help determine the need for rotating companies based on the quality of the work. '}
        , { id:'D3.DC.Th.Int.2', datapoint:'D3.DC.Th.Int.2 - E-mails and attachments are automatically scanned to detect malware and are blocked when malware is present. '}
        , { id:'D3.DC.Th.A.1', datapoint:'D3.DC.Th.A.1 - Weekly vulnerability scanning is rotated among environments to scan all environments throughout the year. '}
        , { id:'D3.DC.Th.A.2', datapoint:'D3.DC.Th.A.2 - Penetration tests include cyber attack simulations and/or real-world tactics and techniques such as red team testing to detect control gaps in employee behavior, security defenses, policies, and resources. '}
        , { id:'D3.DC.Th.A.3', datapoint:'D3.DC.Th.A.3 - Automated tool(s) proactively identifies high-risk behavior signaling an employee who may pose an insider threat. '}
        , { id:'D3.DC.Th.Inn.1', datapoint:'D3.DC.Th.Inn.1 - User tasks and content (e.g., opening an e-mail attachment) are automatically isolated in a secure container or virtual environment so that malware can be analyzed but cannot access vital data, end-point operating systems, or applications on the instituti'}
        , { id:'D3.DC.Th.Inn.2', datapoint:'D3.DC.Th.Inn.2 - Vulnerability scanning is performed on a weekly basis across all environments.'}
        , { id:'D3.DC.An.B.1', datapoint:'D3.DC.An.B.1 - The institution is able to detect anomalous activities through monitoring across the environment. (FFIEC Information Security Booklet, page 32) '}
        , { id:'D3.DC.An.B.2', datapoint:'D3.DC.An.B.2 - Customer transactions generating anomalous activity alerts are monitored and reviewed. (FFIEC Wholesale Payments Booklet, page 12) '}
        , { id:'D3.DC.An.B.3', datapoint:'D3.DC.An.B.3 - Logs of physical and/or logical access are reviewed following events. (FFIEC Information Security Booklet, page 73) '}
        , { id:'D3.DC.An.B.4', datapoint:'D3.DC.An.B.4 - Access to critical systems by third parties is monitored for unauthorized or unusual activity. (FFIEC Outsourcing Booklet, page 26) '}
        , { id:'D3.DC.An.B.5', datapoint:'D3.DC.An.B.5 - Elevated privileges are monitored. (FFIEC Information Security Booklet, page 19)'}
        , { id:'D3.DC.An.E.1', datapoint:'D3.DC.An.E.1 - Systems are in place to detect anomalous behavior automatically during customer, employee, and third-party authentication. '}
        , { id:'D3.DC.An.E.2', datapoint:'D3.DC.An.E.2 - Security logs are reviewed regularly. '}
        , { id:'D3.DC.An.E.3', datapoint:'D3.DC.An.E.3 - Logs provide traceability for all system access by individual users. '}
        , { id:'D3.DC.An.E.4', datapoint:'D3.DC.An.E.4 - Thresholds have been established to determine activity within logs that would warrant management response. '}
        , { id:'D3.DC.An.Int.1', datapoint:'D3.DC.An.Int.1 - Online customer transactions are actively monitored for anomalous behavior. '}
        , { id:'D3.DC.An.Int.2', datapoint:'D3.DC.An.Int.2 - Tools to detect unauthorized data mining are used. '}
        , { id:'D3.DC.An.Int.3', datapoint:'D3.DC.An.Int.3 - Tools actively monitor security logs for anomalous behavior and alert within established parameters. '}
        , { id:'D3.DC.An.Int.4', datapoint:'D3.DC.An.Int.4 - Audit logs are backed up to a centralized log server or media that is difficult to alter. '}
        , { id:'D3.DC.An.Int.5', datapoint:'D3.DC.An.Int.5 - Thresholds for security logging are evaluated periodically.  '}
        , { id:'D3.DC.An.Int.6', datapoint:'D3.DC.An.Int.6 - Anomalous activity and other network and system alerts are correlated across business units to detect and prevent multifaceted attacks (e.g., simultaneous account takeover and DDoS attack). '}
        , { id:'D3.DC.An.A.1', datapoint:'D3.DC.An.A.1 - An automated tool triggers system and/or fraud alerts when customer logins occur within a short period of time but from physically distant IP locations. '}
        , { id:'D3.DC.An.A.2', datapoint:'D3.DC.An.A.2 - External transfers from customer accounts generate alerts and require review and authorization if anomalous behavior is detected. '}
        , { id:'D3.DC.An.A.3', datapoint:'D3.DC.An.A.3 - A system is in place to monitor and analyze employee behavior (network use patterns, work hours, and known devices) to alert on anomalous activities. '}
        , { id:'D3.DC.An.A.4', datapoint:'D3.DC.An.A.4 - An automated tool(s) is in place to detect and prevent data mining by insider threats. '}
        , { id:'D3.DC.An.A.5', datapoint:'D3.DC.An.A.5 - Tags on fictitious confidential data or files are used to provide advanced alerts of potential malicious activity when the data is accessed.'}
        , { id:'D3.DC.An.Inn.1', datapoint:'D3.DC.An.Inn.1 - The institution has a mechanism for real-time automated risk scoring of threats.  '}
        , { id:'D3.DC.An.Inn.2', datapoint:'D3.DC.An.Inn.2 - The institution is developing new technologies that will detect potential insider threats and block activity in real time. '}
        , { id:'D3.DC.Ev.B.1', datapoint:'D3.DC.Ev.B.1 - A normal network activity baseline is established. (FFIEC Information Security Booklet, page 77) '}
        , { id:'D3.DC.Ev.B.2', datapoint:'D3.DC.Ev.B.2 - Mechanisms (e.g., antivirus alerts, log event alerts) are in place to alert management to potential attacks. (FFIEC Information Security Booklet, page 78) '}
        , { id:'D3.DC.Ev.B.3', datapoint:'D3.DC.Ev.B.3 - Processes are in place to monitor for the presence of unauthorized users, devices, connections, and software. (FFIEC Information Security Work Program, Objective II: M-9) '}
        , { id:'D3.DC.Ev.B.4', datapoint:'D3.DC.Ev.B.4 - Responsibilities for monitoring and reporting suspicious systems activity have been assigned. (FFIEC Information Security Booklet, page 83) '}
        , { id:'D3.DC.Ev.B.5', datapoint:'D3.DC.Ev.B.5 - The physical environment is monitored to detect potential unauthorized access. (FFIEC Information Security Booklet, page 47)'}
        , { id:'D3.DC.Ev.E.1', datapoint:'D3.DC.Ev.E.1 - A process is in place to correlate event information from multiple sources (e.g., network, application, or firewall). '}
        , { id:'D3.DC.Ev.Int.1', datapoint:'D3.DC.Ev.Int.1 - Controls or tools (e.g., data loss prevention) are in place to detect potential unauthorized or unintentional transmissions of confidential data. '}
        , { id:'D3.DC.Ev.Int.2', datapoint:'D3.DC.Ev.Int.2 - Event detection processes are proven reliable.  '}
        , { id:'D3.DC.Ev.Int.3', datapoint:'D3.DC.Ev.Int.3 - Specialized security monitoring is used for critical assets throughout the infrastructure.'}
        , { id:'D3.DC.Ev.A.1', datapoint:'D3.DC.Ev.A.1 - Automated tools detect unauthorized changes to critical system files, firewalls, IPS, IDS, or other security devices. '}
        , { id:'D3.DC.Ev.A.2', datapoint:'D3.DC.Ev.A.2 - Real-time network monitoring and detection is implemented and incorporates sector-wide event information. '}
        , { id:'D3.DC.Ev.A.3', datapoint:'D3.DC.Ev.A.3 - Real-time alerts are automatically sent when unauthorized software, hardware, or changes occur. '}
        , { id:'D3.DC.Ev.A.4', datapoint:'D3.DC.Ev.A.4 - Tools are in place to actively correlate event information from multiple sources and send alerts based on established parameters. '}
        , { id:'D3.DC.Ev.Inn.1', datapoint:'D3.DC.Ev.Inn.1 - The institution is leading efforts to develop event detection systems that will correlate in real time when events are about to occur.  '}
        , { id:'D3.DC.Ev.Inn.2', datapoint:'D3.DC.Ev.Inn.2 - The institution is leading the development effort to design new technologies that will detect potential insider threats and block activity in real time. '}
        , { id:'D3.CC.Pa.B.1', datapoint:'D3.CC.Pa.B.1 - A patch management program is implemented and ensures that software and firmware patches are applied in a timely manner. (FFIEC Information Security Booklet, page 62) '}
        , { id:'D3.CC.Pa.B.2', datapoint:'D3.CC.Pa.B.2 - Patches are tested before being applied to systems and/or software. (FFIEC Operations Booklet, page 22) '}
        , { id:'D3.CC.Pa.B.3', datapoint:'D3.CC.Pa.B.3 - Patch management reports are reviewed and reflect missing security patches. (FFIEC Development and Acquisition Booklet, page 50) '}
        , { id:'D3.CC.Pa.E.1', datapoint:'D3.CC.Pa.E.1 - A formal process is in place to acquire, test, and deploy software patches based on criticality. '}
        , { id:'D3.CC.Pa.E.2', datapoint:'D3.CC.Pa.E.2 - Systems are configured to retrieve patches automatically. '}
        , { id:'D3.CC.Pa.E.3', datapoint:'D3.CC.Pa.E.3 - Operational impact is evaluated before deploying security patches. '}
        , { id:'D3.CC.Pa.E.4', datapoint:'D3.CC.Pa.E.4 - An automated tool(s) is used to identify missing security patches as well as the number of days since each patch became available. '}
        , { id:'D3.CC.Pa.E.5', datapoint:'D3.CC.Pa.E.5 - Missing patches across all environments are prioritized and tracked. '}
        , { id:'D3.CC.Pa.Int.1', datapoint:'D3.CC.Pa.Int.1 - Patches for high-risk vulnerabilities are tested and applied when released or the risk is accepted and accountability assigned. '}
        , { id:'D3.CC.Pa.A.1', datapoint:'D3.CC.Pa.A.1 - Patch monitoring software is installed on all servers to identify any missing patches for the operating system software, middleware, database, and other key software. '}
        , { id:'D3.CC.Pa.A.2', datapoint:'D3.CC.Pa.A.2 - The institution monitors patch management reports to ensure security patches are tested and implemented within aggressive time frames (e.g., 0-30 days). '}
        , { id:'D3.CC.Pa.Inn.1', datapoint:'D3.CC.Pa.Inn.1 - The institution develops security patches or bug fixes or contributes to open source code development for systems it uses. '}
        , { id:'D3.CC.Pa.Inn.2', datapoint:'D3.CC.Pa.Inn.2 - Segregated or separate systems are in place that mirror production systems allowing for rapid testing and implementation of patches and provide for rapid fallback when needed. '}
        , { id:'D3.CC.Re.B.1', datapoint:'D3.CC.Re.B.1 - Issues identified in assessments are prioritized and resolved based on criticality and within the time frames established in the response to the assessment report. (FFIEC Information Security Booklet, page 87) '}
        , { id:'D3.CC.Re.E.1', datapoint:'D3.CC.Re.E.1 - Data is destroyed or wiped on hardware and portable/mobile media when a device is missing, stolen, or no longer needed. '}
        , { id:'D3.CC.Re.E.2', datapoint:'D3.CC.Re.E.2 - Formal processes are in place to resolve weaknesses identified during penetration testing. '}
        , { id:'D3.CC.Re.Int.1', datapoint:'D3.CC.Re.Int.1 - Remediation efforts are confirmed by conducting a follow-up vulnerability scan. '}
        , { id:'D3.CC.Re.Int.2', datapoint:'D3.CC.Re.Int.2 - Penetration testing is repeated to confirm that medium- and high-risk, exploitable vulnerabilities have been resolved. '}
        , { id:'D3.CC.Re.Int.3', datapoint:'D3.CC.Re.Int.3 - Security investigations, forensic analysis, and remediation are performed by qualified staff or third parties. '}
        , { id:'D3.CC.Re.Int.4', datapoint:'D3.CC.Re.Int.4 - Generally accepted and appropriate forensic procedures, including chain of custody, are used to gather and present evidence to support potential legal action. '}
        , { id:'D3.CC.Re.Int.5', datapoint:'D3.CC.Re.Int.5 - The maintenance and repair of organizational assets are performed by authorized individuals with approved and controlled tools.  '}
        , { id:'D3.CC.Re.Int.6', datapoint:'D3.CC.Re.Int.6 - The maintenance and repair of organizational assets are logged in a timely manner. '}
        , { id:'D3.CC.Re.A.1', datapoint:'D3.CC.Re.A.1 - All medium and high risk issues identified in penetration testing, vulnerability scanning, and other independent testing are escalated to the board or an appropriate board committee for risk acceptance if not resolved in a timely manner. '}
        , { id:'D3.CC.Re.Inn.1', datapoint:'D3.CC.Re.Inn.1 - The institution is developing technologies that will remediate systems damaged by zero-day attacks to maintain current recovery time objectives.  '}
        , { id:'D4.C.Co.B.1', datapoint:'D4.C.Co.B.1 - The critical business processes that are dependent on external connectivity have been identified. (FFIEC Information Security Booklet, page 9) '}
        , { id:'D4.C.Co.B.2', datapoint:'D4.C.Co.B.2 - The institution ensures that third-party connections are authorized. (FFIEC Information Security Booklet, page 17) '}
        , { id:'D4.C.Co.B.3', datapoint:'D4.C.Co.B.3 - A network diagram is in place and identifies all external connections. (FFIEC Information Security Booklet, page 9) '}
        , { id:'D4.C.Co.B.4', datapoint:'D4.C.Co.B.4 - Data flow diagrams are in place and document information flow to external parties. (FFIEC Information Security Booklet, page 10) '}
        , { id:'D4.C.Co.E.1', datapoint:'D4.C.Co.E.1 - Critical business processes have been mapped to the supporting external connections. '}
        , { id:'D4.C.Co.E.2', datapoint:'D4.C.Co.E.2 - The network diagram is updated when connections with third parties change or at least annually. '}
        , { id:'D4.C.Co.E.3', datapoint:'D4.C.Co.E.3 - Network and systems diagrams are stored in a secure manner with proper restrictions on access. '}
        , { id:'D4.C.Co.E.4', datapoint:'D4.C.Co.E.4 - Controls for primary and backup third-party connections are monitored and tested on a regular basis.'}
        , { id:'D4.C.Co.Int.1', datapoint:'D4.C.Co.Int.1 - A validated asset inventory is used to create comprehensive diagrams depicting data repositories, data flow, infrastructure, and connectivity. '}
        , { id:'D4.C.Co.Int.2', datapoint:'D4.C.Co.Int.2 - Security controls are designed and verified to detect and prevent intrusions from third-party connections. '}
        , { id:'D4.C.Co.Int.3', datapoint:'D4.C.Co.Int.3 - Monitoring controls cover all external connections (e.g., third-party service providers, business partners, customers). '}
        , { id:'D4.C.Co.Int.4', datapoint:'D4.C.Co.Int.4 - Monitoring controls cover all internal network-to-network connections. '}
        , { id:'D4.C.Co.A.1', datapoint:'D4.C.Co.A.1 - The security architecture is validated and documented before network connection infrastructure changes. '}
        , { id:'D4.C.Co.A.2', datapoint:'D4.C.Co.A.2 - The institution works closely with third-party service providers to maintain and improve the security of external connections. '}
        , { id:'D4.C.Co.Inn.1', datapoint:'D4.C.Co.Inn.1 - Diagram(s) of external connections is interactive, shows real-time changes to the network connection infrastructure, new connections, and volume fluctuations, and alerts when risks arise. '}
        , { id:'D4.C.Co.Inn.2', datapoint:'D4.C.Co.Inn.2 - The institution’s connections can be segmented or severed instantaneously to prevent contagion from cyber attacks. '}
        , { id:'D4.RM.Dd.B.1', datapoint:'D4.RM.Dd.B.1 - Risk-based due diligence is performed on prospective third parties before contracts are signed, including reviews of their background, reputation, financial condition, stability, and security controls. (FFIEC Information Security Booklet, page 69) '}
        , { id:'D4.RM.Dd.B.2', datapoint:'D4.RM.Dd.B.2 - A list of third-party service providers is maintained. (FFIEC Outsourcing Booklet, page 19) '}
        , { id:'D4.RM.Dd.B.3', datapoint:'D4.RM.Dd.B.3 - A risk assessment is conducted to identify criticality of service providers. (FFIEC Outsourcing Booklet, page 6)'}
        , { id:'D4.RM.Dd.E.1', datapoint:'D4.RM.Dd.E.1 - A formal process exists to analyze assessments of third-party cybersecurity controls. '}
        , { id:'D4.RM.Dd.E.2', datapoint:'D4.RM.Dd.E.2 - The board or an appropriate board committee reviews a summary of due diligence results including management’s recommendations to use third parties that will affect the institution’s inherent risk profile. '}
        , { id:'D4.RM.Dd.Int.1', datapoint:'D4.RM.Dd.Int.1 - A process is in place to confirm that the institution’s third-party service providers conduct due diligence of their third parties (e.g., subcontractors). '}
        , { id:'D4.RM.Dd.Int.2', datapoint:'D4.RM.Dd.Int.2 - Pre-contract, physical site visits of high-risk vendors are conducted by the institution or by a qualified third party.'}
        , { id:'D4.RM.Dd.A.1', datapoint:'D4.RM.Dd.A.1 - A continuous process improvement program is in place for third-party due diligence activity. '}
        , { id:'D4.RM.Dd.A.2', datapoint:'D4.RM.Dd.A.2 - Audits of high-risk vendors are conducted on an annual basis. '}
        , { id:'D4.RM.Dd.Inn.1', datapoint:'D4.RM.Dd.Inn.1 - The institution promotes sector-wide efforts to build due diligence mechanisms that lead to in-depth and efficient security and resilience reviews. '}
        , { id:'D4.RM.Dd.Inn.2', datapoint:'D4.RM.Dd.Inn.2 - The institution is leading efforts to develop new auditable processes and for conducting due diligence and ongoing monitoring of cybersecurity risks posed by third parties. '}
        , { id:'D4.RM.Co.B.1', datapoint:'D4.RM.Co.B.1 - Formal contracts that address relevant security and privacy requirements are in place for all third parties that process, store, or transmit confidential data or provide critical services. (FFIEC Information Security Booklet, page 7) '}
        , { id:'D4.RM.Co.B.2', datapoint:'D4.RM.Co.B.2 - Contracts acknowledge that the third party is responsible for the security of the institution’s confidential data that it possesses, stores, processes, or transmits. (FFIEC Information Security Booklet, page 12) '}
        , { id:'D4.RM.Co.B.3', datapoint:'D4.RM.Co.B.3 - Contracts stipulate that the third-party security controls are regularly reviewed and validated by an independent party. (FFIEC Information Security Booklet, page 12) '}
        , { id:'D4.RM.Co.B.4', datapoint:'D4.RM.Co.B.4 - Contracts identify the recourse available to the institution should the third party fail to meet defined security requirements. (FFIEC Outsourcing Booklet, page 12) '}
        , { id:'D4.RM.Co.B.5', datapoint:'D4.RM.Co.B.5 - Contracts establish responsibilities for responding to security incidents. (FFIEC E-Banking Booklet, page 22) '}
        , { id:'D4.RM.Co.B.6', datapoint:'D4.RM.Co.B.6 - Contracts specify the security requirements for the return or destruction of data upon contract termination. (FFIEC Outsourcing Booklet, page 15)'}
        , { id:'D4.RM.Co.E.1', datapoint:'D4.RM.Co.E.1 - Responsibilities for managing devices (e.g., firewalls, routers) that secure connections with third parties are formally documented in the contract. '}
        , { id:'D4.RM.Co.E.2', datapoint:'D4.RM.Co.E.2 - Responsibility for notification of direct and indirect security incidents and vulnerabilities is documented in contracts or service-level agreements (SLAs). '}
        , { id:'D4.RM.Co.E.3', datapoint:'D4.RM.Co.E.3 - Contracts stipulate geographic limits on where data can be stored or transmitted.'}
        , { id:'D4.RM.Co.Int.1', datapoint:'D4.RM.Co.Int.1 - Third-party SLAs or similar means are in place that require timely notification of security events'}
        , { id:'D4.RM.Co.A.1', datapoint:'D4.RM.Co.A.1 - Contracts require third-party service provider’s security policies meet or exceed those of the institution. '}
        , { id:'D4.RM.Co.A.2', datapoint:'D4.RM.Co.A.2 - A third-party termination/exit strategy has been established and validated with management. '}
        , { id:'D4.RM.Co.Inn.1', datapoint:'D4.RM.Co.Inn.1 - The institution promotes a sector-wide effort to influence contractual requirements for critical third parties to the industry. '}
        , { id:'D4.RM.Om.B.1', datapoint:'D4.RM.Om.B.1 - The third-party risk assessment is updated regularly. (FFIEC Outsourcing Booklet, page 3) '}
        , { id:'D4.RM.Om.B.2', datapoint:'D4.RM.Om.B.2 - Audits, assessments, and operational performance reports are obtained and reviewed regularly validating security controls for critical third parties. (FFIEC Information Security Booklet, page 86) '}
        , { id:'D4.RM.Om.B.3', datapoint:'D4.RM.Om.B.3 - Ongoing monitoring practices include reviewing critical third-parties’ resilience plans. (FFIEC Outsourcing Booklet, page 19) '}
        , { id:'D4.RM.Om.E.1', datapoint:'D4.RM.Om.E.1 - A process to identify new third-party relationships is in place, including identifying new relationships that were established without formal approval. '}
        , { id:'D4.RM.Om.E.2', datapoint:'D4.RM.Om.E.2 - A formal program assigns responsibility for ongoing oversight of thirdparty access. '}
        , { id:'D4.RM.Om.E.3', datapoint:'D4.RM.Om.E.3 - Monitoring of third parties is scaled, in terms of depth and frequency, according to the risk of the third parties. '}
        , { id:'D4.RM.Om.E.4', datapoint:'D4.RM.Om.E.4 - Automated reminders or ticklers are in place to identify when required third-party information needs to be obtained or analyzed.'}
        , { id:'D4.RM.Om.Int.1', datapoint:'D4.RM.Om.Int.1 - Third-party employee access to the institution’s confidential data are tracked actively based on the principles of least privilege. '}
        , { id:'D4.RM.Om.Int.2', datapoint:'D4.RM.Om.Int.2 - Periodic on-site assessments of high-risk vendors are conducted to ensure appropriate security controls are in place. '}
        , { id:'D4.RM.Om.A.1', datapoint:'D4.RM.Om.A.1 - Third-party employee access to confidential data on third-party hosted systems is tracked actively via automated reports and alerts. '}
        , { id:'D4.RM.Om.Inn.1', datapoint:'D4.RM.Om.Inn.1 - The institution is leading efforts to develop new auditable processes for ongoing monitoring of cybersecurity risks posed by third parties. '}
        , { id:'D5.IR.Pl.B.1', datapoint:'D5.IR.Pl.B.1 - The institution has documented how it will react and respond to cyber incidents. (FFIEC Business Continuity Planning Booklet, page 4) '}
        , { id:'D5.IR.Pl.B.2', datapoint:'D5.IR.Pl.B.2 - Communication channels exist to provide employees a means for reporting information security events in a timely manner. (FFIEC Information Security Booklet, page 83) '}
        , { id:'D5.IR.Pl.B.3', datapoint:'D5.IR.Pl.B.3 - Roles and responsibilities for incident response team members are defined. (FFIEC Information Security Booklet, page 84) '}
        , { id:'D5.IR.Pl.B.4', datapoint:'D5.IR.Pl.B.4 - The response team includes individuals with a wide range of backgrounds and expertise, from many different areas within the institution (e.g., management, legal, public relations, as well as information technology). (FFIEC Information Security Booklet, pa'}
        , { id:'D5.IR.Pl.B.5', datapoint:'D5.IR.Pl.B.5 - A formal backup and recovery plan exists for all critical business lines. (FFIEC Business Continuity Planning Booklet, page 4) '}
        , { id:'D5.IR.Pl.B.6', datapoint:'D5.IR.Pl.B.6 - The institution plans to use business continuity, disaster recovery, and data backup programs to recover operations following an incident. (FFIEC Information Security Booklet, page 71)'}
        , { id:'D5.IR.Pl.E.1', datapoint:'D5.IR.Pl.E.1 - The remediation plan and process outlines the mitigating actions, resources, and time parameters. '}
        , { id:'D5.IR.Pl.E.2', datapoint:'D5.IR.Pl.E.2 - The corporate disaster recovery, business continuity, and crisis management plans have integrated consideration of cyber incidents. '}
        , { id:'D5.IR.Pl.E.3', datapoint:'D5.IR.Pl.E.3 - Alternative processes have been established to continue critical activity within a reasonable time period. '}
        , { id:'D5.IR.Pl.E.4', datapoint:'D5.IR.Pl.E.4 - Business impact analyses have been updated to include cybersecurity. '}
        , { id:'D5.IR.Pl.E.5', datapoint:'D5.IR.Pl.E.5 - Due diligence has been performed on technical sources, consultants, or forensic service firms that could be called to assist the institution during or following an incident. '}
        , { id:'D5.IR.Pl.Int.1', datapoint:'D5.IR.Pl.Int.1 - A strategy is in place to coordinate and communicate with internal and external stakeholders during or following a cyber attack. '}
        , { id:'D5.IR.Pl.Int.2', datapoint:'D5.IR.Pl.Int.2 - Plans are in place to re-route or substitute critical functions and/or services that may be affected by a successful attack on Internet-facing systems. '}
        , { id:'D5.IR.Pl.Int.3', datapoint:'D5.IR.Pl.Int.3 - A direct cooperative or contractual agreement(s) is in place with an incident response organization(s) or provider(s) to assist rapidly with mitigation efforts. '}
        , { id:'D5.IR.Pl.Int.4', datapoint:'D5.IR.Pl.Int.4 - Lessons learned from real-life cyber incidents and attacks on the institution and other organizations are used to improve the institution’s risk mitigation capabilities and response plan. '}
        , { id:'D5.IR.Pl.A.1', datapoint:'D5.IR.Pl.A.1 - Methods for responding to and recovering from cyber incidents are tightly woven throughout the business units’ disaster recovery, business continuity, and crisis management plans. '}
        , { id:'D5.IR.Pl.A.2', datapoint:'D5.IR.Pl.A.2 - Multiple systems, programs, or processes are implemented into a comprehensive cyber resilience program to sustain, minimize, and recover operations from an array of potentially disruptive and destructive cyber incidents. '}
        , { id:'D5.IR.Pl.A.3', datapoint:'D5.IR.Pl.A.3 - A process is in place to continuously improve the resilience plan. '}
        , { id:'D5.IR.Pl.Inn.1', datapoint:'D5.IR.Pl.Inn.1 - The incident response plan is designed to ensure recovery from disruption of services, assurance of data integrity, and recovery of lost or corrupted data following a cybersecurity incident. '}
        , { id:'D5.IR.Pl.Inn.2', datapoint:'D5.IR.Pl.Inn.2 - The incident response process includes detailed actions and rulebased triggers for automated response. '}
        , { id:'D5.IR.Te.B.1', datapoint:'D5.IR.Te.B.1 - Scenarios are used to improve incident detection and response. (FFIEC Information Security Booklet, page 71) '}
        , { id:'D5.IR.Te.B.2', datapoint:'D5.IR.Te.B.2 - Business continuity testing involves collaboration with critical third parties. (FFIEC Business Continuity Planning Booklet, page J-6) '}
        , { id:'D5.IR.Te.B.3', datapoint:'D5.IR.Te.B.3 - Systems, applications, and data recovery is tested at least annually. (FFIEC Business Continuity Planning Booklet, page J-7) '}
        , { id:'D5.IR.Te.E.1', datapoint:'D5.IR.Te.E.1 - Recovery scenarios include plans to recover from data destruction and impacts to data integrity, data loss, and system and data availability. '}
        , { id:'D5.IR.Te.E.2', datapoint:'D5.IR.Te.E.2 - Widely reported events are used to evaluate and improve the institution’s response. '}
        , { id:'D5.IR.Te.E.3', datapoint:'D5.IR.Te.E.3 - Information backups are tested periodically to verify they are accessible and readable. '}
        , { id:'D5.IR.Te.Int.1', datapoint:'D5.IR.Te.Int.1 - Cyber-attack scenarios are analyzed to determine potential impact to critical business processes. '}
        , { id:'D5.IR.Te.Int.2', datapoint:'D5.IR.Te.Int.2 - The institution participates in sector-specific cyber exercises or scenarios (e.g., FS-ISAC Cyber Attack (against) Payment Processors (CAPP)). '}
        , { id:'D5.IR.Te.Int.3', datapoint:'D5.IR.Te.Int.3 - Resilience testing is based on analysis and identification of realistic and highly likely threats as well as new and emerging threats facing the institution. '}
        , { id:'D5.IR.Te.Int.4', datapoint:'D5.IR.Te.Int.4 - The critical online systems and processes are tested to withstand stresses for extended periods (e.g., DDoS). '}
        , { id:'D5.IR.Te.Int.5', datapoint:'D5.IR.Te.Int.5 - The results of cyber event exercises are used to improve the incident response plan and automated triggers. '}
        , { id:'D5.IR.Te.A.1', datapoint:'D5.IR.Te.A.1 - Resilience testing is comprehensive and coordinated across all critical business functions. '}
        , { id:'D5.IR.Te.A.2', datapoint:'D5.IR.Te.A.2 - The institution validates that it is able to recover from cyber events similar to by known sophisticated attacks at other organizations.  '}
        , { id:'D5.IR.Te.A.3', datapoint:'D5.IR.Te.A.3 - Incident response testing evaluates the institution from an attacker’s perspective to determine how the institution or its assets at critical third parties may be targeted. '}
        , { id:'D5.IR.Te.A.4', datapoint:'D5.IR.Te.A.4 - The institution corrects root causes for problems discovered during cybersecurity resilience testing.  '}
        , { id:'D5.IR.Te.A.5', datapoint:'D5.IR.Te.A.5 - Cybersecurity incident scenarios involving significant financial loss are used to stress test the institution’s risk management. '}
        , { id:'D5.IR.Te.Inn.1', datapoint:'D5.IR.Te.Inn.1 - The institution tests the ability to shift business processes or functions between different processing centers or technology systems for cyber incidents without interruption to business or loss of productivity or data. '}
        , { id:'D5.IR.Te.Inn.2', datapoint:'D5.IR.Te.Inn.2 - The institution has validated that it is able to remediate systems damaged by zero-day attacks to maintain current recovery time objectives. '}
        , { id:'D5.IR.Te.Inn.3', datapoint:'D5.IR.Te.Inn.3 - The institution is leading the development of more realistic test environments. '}
        , { id:'D5.IR.Te.Inn.4', datapoint:'D5.IR.Te.Inn.4 - Cyber incident scenarios are used to stress test potential financial losses across the sector. '}
        , { id:'D5.DR.De.B.1', datapoint:'D5.DR.De.B.1 - Alert parameters are set for detecting information security incidents that prompt mitigating actions. (FFIEC Information Security Booklet, page 43) '}
        , { id:'D5.DR.De.B.2', datapoint:'D5.DR.De.B.2 - System performance reports contain information that can be used as a risk indicator to detect information security incidents. (FFIEC Information Security Booklet, page 86) '}
        , { id:'D5.DR.De.B.3', datapoint:'D5.DR.De.B.3 - Tools and processes are in place to detect, alert, and trigger the incident response program. (FFIEC Information Security Booklet, page 84) '}
        , { id:'D5.DR.De.E.1', datapoint:'D5.DR.De.E.1 - The institution has processes to detect and alert the incident response team when potential insider activity manifests that could lead to data theft or destruction. '}
        , { id:'D5.DR.De.Int.1', datapoint:'D5.DR.De.Int.1 - The incident response program is triggered when anomalous behaviors and attack patterns or signatures are detected. '}
        , { id:'D5.DR.De.Int.2', datapoint:'D5.DR.De.Int.2 - The institution has the ability to discover infiltration, before the attacker traverses across systems, establishes a foothold, steals information, or causes damage to data and systems. '}
        , { id:'D5.DR.De.Int.3', datapoint:'D5.DR.De.Int.3 - Incidents are detected in real time through automated processes that include instant alerts to appropriate personnel who can respond. '}
        , { id:'D5.DR.De.Int.4', datapoint:'D5.DR.De.Int.4 - Network and system alerts are correlated across business units to better detect and prevent multifaceted attacks (e.g., simultaneous DDoS attack and account takeover). '}
        , { id:'D5.DR.De.Int.5', datapoint:'D5.DR.De.Int.5 - Incident detection processes are capable of correlating events across the enterprise.'}
        , { id:'D5.DR.De.A.1', datapoint:'D5.DR.De.A.1 - Sophisticated and adaptive technologies are deployed that can detect and alert the incident response team of specific tasks when threat indicators across the enterprise indicate potential external and internal threats. '}
        , { id:'D5.DR.De.A.2', datapoint:'D5.DR.De.A.2 - Automated tools are implemented to provide specialized security monitoring based on the risk of the assets to detect and alert incident response teams in real time. '}
        , { id:'D5.DR.De.Inn.1', datapoint:'D5.DR.De.Inn.1 - The institution is able to detect and block zero-day attempts and inform management and the incident response team in real time. '}
        , { id:'D5.DR.Re.B.1', datapoint:'D5.DR.Re.B.1 - Appropriate steps are taken to contain and control an incident to prevent further unauthorized access to or use of customer information. (FFIEC Information Security Booklet, page 84) '}
        , { id:'D5.DR.Re.E.1', datapoint:'D5.DR.Re.E.1 - The incident response plan is designed to prioritize incidents, enabling a rapid response for significant cybersecurity incidents or vulnerabilities. '}
        , { id:'D5.DR.Re.E.2', datapoint:'D5.DR.Re.E.2 - A process is in place to help contain incidents and restore operations with minimal service disruption. '}
        , { id:'D5.DR.Re.E.3', datapoint:'D5.DR.Re.E.3 - Containment and mitigation strategies are developed for multiple incident types (e.g., DDoS, malware). '}
        , { id:'D5.DR.Re.E.4', datapoint:'D5.DR.Re.E.4 - Procedures include containment strategies and notifying potentially impacted third parties. '}
        , { id:'D5.DR.Re.E.5', datapoint:'D5.DR.Re.E.5 - Processes are in place to trigger the incident response program when an incident occurs at a third party. '}
        , { id:'D5.DR.Re.E.6', datapoint:'D5.DR.Re.E.6 - Records are generated to support incident investigation and mitigation. '}
        , { id:'D5.DR.Re.E.7', datapoint:'D5.DR.Re.E.7 - The institution calls upon third parties, as needed, to provide mitigation services. '}
        , { id:'D5.DR.Re.E.8', datapoint:'D5.DR.Re.E.8 - Analysis of events is used to improve the institution’s security measures and policies. '}
        , { id:'D5.DR.Re.Int.1', datapoint:'D5.DR.Re.Int.1 - Analysis of security incidents is performed in the early stages of an intrusion to minimize the impact of the incident. '}
        , { id:'D5.DR.Re.Int.2', datapoint:'D5.DR.Re.Int.2 - Any changes to systems/applications or to access entitlements necessary for incident management are reviewed by management for formal approval before implementation. '}
        , { id:'D5.DR.Re.Int.3', datapoint:'D5.DR.Re.Int.3 - Processes are in place to ensure assets affected by a security incident that cannot be returned to operational status are quarantined, removed, disposed of, and/or replaced. '}
        , { id:'D5.DR.Re.Int.4', datapoint:'D5.DR.Re.Int.4 - Processes are in place to ensure that restored assets are appropriately reconfigured and thoroughly tested before being placed back into operation.'}
        , { id:'D5.DR.Re.A.1', datapoint:'D5.DR.Re.A.1 - The incident management function collaborates effectively with the cyber threat intelligence function during an incident. '}
        , { id:'D5.DR.Re.A.2', datapoint:'D5.DR.Re.A.2 - Links between threat intelligence, network operations, and incident response allow for proactive response to potential incidents. '}
        , { id:'D5.DR.Re.A.3', datapoint:'D5.DR.Re.A.3 - Technical measures apply defense-in-depth techniques such as deeppacket inspection and black holing for detection and timely response to network-based attacks associated with anomalous ingress or egress traffic patterns and/or DDoS attacks. '}
        , { id:'D5.DR.Re.Inn.1', datapoint:'D5.DR.Re.Inn.1 - The institution’s risk management of significant cyber incidents results in limited to no disruptions to critical services. '}
        , { id:'D5.DR.Re.Inn.2', datapoint:'D5.DR.Re.Inn.2 - The technology infrastructure has been engineered to limit the effects of a cyber attack on the production environment from migrating to the backup environment (e.g., air-gapped environment and processes). '}
        , { id:'D5.ER.Es.B.1', datapoint:'D5.ER.Es.B.1 - A process exists to contact personnel who are responsible for analyzing and responding to an incident. (FFIEC Information Security Booklet, page 83) '}
        , { id:'D5.ER.Es.B.2', datapoint:'D5.ER.Es.B.2 - Procedures exist to notify customers, regulators, and law enforcement as required or necessary when the institution becomes aware of an incident involving the unauthorized access to or use of sensitive customer information. (FFIEC Information Security Boo'}
        , { id:'D5.ER.Es.B.3', datapoint:'D5.ER.Es.B.3 - The institution prepares an annual report of security incidents or violations for the board or an appropriate board committee. (FFIEC Information Security Booklet, page 5) '}
        , { id:'D5.ER.Es.B.4', datapoint:'D5.ER.Es.B.4 - Incidents are classified, logged, and tracked. (FFIEC Operations Booklet, page 28) '}
        , { id:'D5.ER.Es.E.1', datapoint:'D5.ER.Es.E.1 - Criteria have been established for escalating cyber incidents or vulnerabilities to the board and senior management based on the potential impact and criticality of the risk. '}
        , { id:'D5.ER.Es.E.2', datapoint:'D5.ER.Es.E.2 - Regulators, law enforcement, and service providers, as appropriate, are notified when the institution is aware of any unauthorized access to systems or a cyber incident occurs that could result in degradation of services. '}
        , { id:'D5.ER.Es.E.3', datapoint:'D5.ER.Es.E.3 - Tracked cyber incidents are correlated for trend analysis and reporting. '}
        , { id:'D5.ER.Es.Int.1', datapoint:'D5.ER.Es.Int.1 - Employees that are essential to mitigate the risk (e.g., fraud, business resilience) know their role in incident escalation. '}
        , { id:'D5.ER.Es.Int.2', datapoint:'D5.ER.Es.Int.2 - A communication plan is used to notify other organizations, including third parties, of incidents that may affect them or their customers. '}
        , { id:'D5.ER.Es.Int.3', datapoint:'D5.ER.Es.Int.3 - An external communication plan is used for notifying media regarding incidents when applicable. '}
        , { id:'D5.ER.Es.A.1', datapoint:'D5.ER.Es.A.1 - The institution has established quantitative and qualitative metrics for the cybersecurity incident response process. '}
        , { id:'D5.ER.Es.A.2', datapoint:'D5.ER.Es.A.2 - Detailed metrics, dashboards, and/or scorecards outlining cyber incidents and events are provided to management and are part of the board meeting package. '}
        , { id:'D5.ER.Es.Inn.1', datapoint:'D5.ER.Es.Inn.1 - A mechanism is in place to provide instantaneous notification of incidents to management and essential employees through multiple communication channels with tracking and verification of receipt. '}
    ]
    )
}
const GetNistCSF = ()=>{
    
    return ([
          { id:'ID.AM-1', datapoint:'ID.AM-1 - Physical devices and systems within the organization are inventoried'}
        , { id:'ID.AM-2', datapoint:'ID.AM-2 - Software platforms and applications within the organization are inventoried'}
        , { id:'ID.AM-3', datapoint:'ID.AM-3 - Organizational communication and data flows are mapped'}
        , { id:'ID.AM-4', datapoint:'ID.AM-4 - External information systems are catalogued'}
        , { id:'ID.AM-5', datapoint:'ID.AM-5 - Resources (e.g., hardware,devices, data, and software) are prioritized based on their classification, criticality, and business value.'}
        , { id:'ID.AM-6', datapoint:'ID.AM-6 - Cybersecurity roles and responsibilities for the entire workforce and third-party stakeholders (e.g., suppliers, customers, partners) are established.'}
        , { id:'ID.BE-1', datapoint:'ID.BE-1 - The organization’s role in the supply chain is identified and communicated'}
        , { id:'ID.BE-2', datapoint:'ID.BE-2 - The organization’s place in critical infrastructure and its industry sector is identified and communicated'}
        , { id:'ID.BE-3', datapoint:'ID.BE-3 - Priorities for organizational mission, objectives, and activities are established and communicated'}
        , { id:'ID.BE-4', datapoint:'ID.BE-4 - Dependencies and critical functions for delivery of critical services are established'}
        , { id:'ID.BE-5', datapoint:'ID.BE-5 - Resilience requirements to support delivery of critical services are established'}
        , { id:'ID.GV-1', datapoint:'ID.GV-1 - Organizational information security policy is established'}
        , { id:'ID.GV-2', datapoint:'ID.GV-2 - Information security roles & responsibilities are coordinated and aligned with internal roles and external partners'}
        , { id:'ID.GV-3', datapoint:'ID.GV-3 - Legal and regulatory requirements regarding cybersecurity,including privacy and civil liberties obligations, are understood and managed'}
        , { id:'ID.GV-4', datapoint:'ID.GV-4 - Governance and risk management processes address cybersecurity risks'}
        , { id:'ID.RA-1', datapoint:'ID.RA-1 - Asset vulnerabilities are identified and documented'}
        , { id:'ID.RA-2', datapoint:'ID.RA-2 - Threat and vulnerability information is received from information sharing forums and sources'}
        , { id:'ID.RA-3', datapoint:'ID.RA-3 - Threats, both internal and external, are identified and documented'}
        , { id:'ID.RA-4', datapoint:'ID.RA-4 - Potential business impacts and likelihoods are identified'}
        , { id:'ID.RA-5', datapoint:'ID.RA-5 - Threats, vulnerabilities, likelihoods, and impacts are used to determine risk'}
        , { id:'ID.RA-6', datapoint:'ID.RA-6 - Risk responses are identified and prioritized.'}
        , { id:'ID.RM-1', datapoint:'ID.RM-1 - Risk management processes are established, managed, and agreed to by organizational stakeholders'}
        , { id:'ID.RM-2', datapoint:'ID.RM-2 - Organizational risk tolerance is determined and clearly expressed'}
        , { id:'ID.RM-3', datapoint:'ID.RM-3 - The organization’s determination of risk tolerance is informed by its role in critical infrastructure and sector specific risk analysis'}
        , { id:'PR.AC-1', datapoint:'PR.AC-1 - Identities and credentials are managed for authorized devices and users'}
        , { id:'PR.AC-2', datapoint:'PR.AC-2 - Physical access to assets is managed and protected'}
        , { id:'PR.AC-3', datapoint:'PR.AC-3 - Remote access is managed'}
        , { id:'PR.AC-4', datapoint:'PR.AC-4 - Access permissions are managed, incorporating the principles of least privilege and separation of duties'}
        , { id:'PR.AC-5', datapoint:'PR.AC-5 - Network integrity is protected, incorporating network segregation where appropriate'}
        , { id:'PR.AT-1', datapoint:'PR.AT-1 - All users are informed and trained'}
        , { id:'PR.AT-2', datapoint:'PR.AT-2 - Privileged users understand roles & responsibilities'}
        , { id:'PR.AT-3', datapoint:'PR.AT-3 - Third-party stakeholders (e.g., suppliers, customers, partners) understand roles & responsibilities'}
        , { id:'PR.AT-4', datapoint:'PR.AT-4 - Senior executives understand roles & responsibilities'}
        , { id:'PR.AT-5', datapoint:'PR.AT-5 - Physical and information security personnel understand roles & responsibilities'}
        , { id:'PR.DS-1', datapoint:'PR.DS-1 - Data-at-rest is protected'}
        , { id:'PR.DS-2', datapoint:'PR.DS-2 - Data-in-transit is protected'}
        , { id:'PR.DS-3', datapoint:'PR.DS-3 - Assets are formally managed throughout removal, transfers, and disposition'}
        , { id:'PR.DS-4', datapoint:'PR.DS-4 - Adequate capacity to ensure availability is maintained'}
        , { id:'PR.DS-5', datapoint:'PR.DS-5 - Protections against data leaks are implemented.'}
        , { id:'PR.DS-6', datapoint:'PR.DS-6 - Integrity checking mechanisms are used to verify software, firmware, and information integrity'}
        , { id:'PR.DS-7', datapoint:'PR.DS-7 - The development and testing environment(s) are separate from the production environment'}
        , { id:'PR.IP-1', datapoint:'PR.IP-1 - A baseline configuration of information technology/industrial control systems is created and maintained'}
        , { id:'PR.IP-2', datapoint:'PR.IP-2 - A System Development Life Cycle to manage systems is implemented'}
        , { id:'PR.IP-3', datapoint:'PR.IP-3 - Configuration change control processes are in place'}
        , { id:'PR.IP-4', datapoint:'PR.IP-4 - Backups of information are conducted, maintained, and tested periodically'}
        , { id:'PR.IP-5', datapoint:'PR.IP-5 - Policy and regulations regarding the physical operating environment for organizational assets are met'}
        , { id:'PR.IP-6', datapoint:'PR.IP-6 - Data is destroyed according to policy'}
        , { id:'PR.IP-7', datapoint:'PR.IP-7 - Protection processes are continuously improved'}
        , { id:'PR.IP-8', datapoint:'PR.IP-8 - Effectiveness of protection technologies is shared with appropriate parties'}
        , { id:'PR.IP-9', datapoint:'PR.IP-9 - Response and Business Continuity) and recovery plans (Incident Recovery and Disaster Recovery) are in place and managed'}
        , { id:'PR.IP-10', datapoint:'PR.IP-10 - Response and recovery plans are tested'}
        , { id:'PR.IP-11', datapoint:'PR.IP-11 - Cybersecurity is included in human resources practices (e.g., deprovisioning, personnel screening)'}
        , { id:'PR.IP-12', datapoint:'PR.IP-12 - A vulnerability management plan is developed and implemented'}
        , { id:'PR.MA-1', datapoint:'PR.MA-1 - Maintenance and repair of organizational assets is performed and logged in a timely manner, with approved and controlled tools'}
        , { id:'PR.MA-2', datapoint:'PR.MA-2 - Remote maintenance of organizational assets is approved, logged, and performed in a manner that prevents unauthorized access'}
        , { id:'PR.PT-1', datapoint:'PR.PT-1 - Audit/log records are determined, documented, implemented, and reviewed in accordance with policy'}
        , { id:'PR.PT-2', datapoint:'PR.PT-2 - Removable media is protected and its use restricted according to policy'}
        , { id:'PR.PT-3', datapoint:'PR.PT-3 - Access to systems and assets is controlled, incorporating the principle of least functionality'}
        , { id:'PR.PT-4', datapoint:'PR.PT-4 - Communications and control networks are protected'}
        , { id:'DE.AE-1', datapoint:'DE.AE-1 - A baseline of network operations and expected data flows for users and systems is established and managed'}
        , { id:'DE.AE-2', datapoint:'DE.AE-2 - Detected events are analyzed to understand attack targets and methods'}
        , { id:'DE.AE-3', datapoint:'DE.AE-3 - Event data are aggregated and correlated from multiple sources and sensors'}
        , { id:'DE.AE-4', datapoint:'DE.AE-4 - Impact of events is determined'}
        , { id:'DE.AE-5', datapoint:'DE.AE-5 - Incident alert thresholds are established'}
        , { id:'DE.CM-1', datapoint:'DE.CM-1 - The network is monitored to detect potential cybersecurity events'}
        , { id:'DE.CM-2', datapoint:'DE.CM-2 - The physical environment is monitored to detect potential cybersecurity events'}
        , { id:'DE.CM-3', datapoint:'DE.CM-3 - Personnel activity is monitored to detect potential cybersecurity events'}
        , { id:'DE.CM-4', datapoint:'DE.CM-4 - Malicious code is detected'}
        , { id:'DE.CM-5', datapoint:'DE.CM-5 - Unauthorized mobile code is detected'}
        , { id:'DE.CM-6', datapoint:'DE.CM-6 - External service provider activity is monitored to detect potential cybersecurity events'}
        , { id:'DE.CM-7', datapoint:'DE.CM-7 - Monitoring for unauthorized personnel, connections, devices, and software is performed'}
        , { id:'DE.CM-8', datapoint:'DE.CM-8 - Vulnerability scans are performed'}
        , { id:'DE.DP-1', datapoint:'DE.DP-1 - Roles and responsibilities for detection are well defined to ensure accountability'}
        , { id:'DE.DP-2', datapoint:'DE.DP-2 - Detection activities comply with all applicable requirements'}
        , { id:'DE.DP-3', datapoint:'DE.DP-3 - Detection processes are tested'}
        , { id:'DE.DP-4', datapoint:'DE.DP-4 - Event detection information is communicated to appropriate parties'}
        , { id:'DE.DP-5', datapoint:'DE.DP-5 - Detection processes are continuously improved'}
        , { id:'RS.RP-1', datapoint:'RS.RP-1 - Response plan is executed during or after an event'}
        , { id:'RS.CO-1', datapoint:'RS.CO-1 - Personnel know their roles and order of operations when a response is needed'}
        , { id:'RS.CO-2', datapoint:'RS.CO-2 - Events are reported consistent with established criteria'}
        , { id:'RS.CO-3', datapoint:'RS.CO-3 - Information is shared consistent with response plans'}
        , { id:'RS.CO-4', datapoint:'RS.CO-4 - Coordination with stakeholders occurs consistent with response plans'}
        , { id:'RS.CO-5', datapoint:'RS.CO-5 - Voluntary information sharing occurs with external stakeholders to achieve broader cybersecurity situational awareness'}
        , { id:'RS.AN-1', datapoint:'RS.AN-1 - Notifications from detection systems are investigated'}
        , { id:'RS.AN-2', datapoint:'RS.AN-2 - The impact of the incident is understood'}
        , { id:'RS.AN-3', datapoint:'RS.AN-3 - Forensics are performed'}
        , { id:'RS.AN-4', datapoint:'RS.AN-4 - Incidents are categorized consistent with response plans'}
        , { id:'RS.MI-1', datapoint:'RS.MI-1 - Incidents are contained'}
        , { id:'RS.MI-2', datapoint:'RS.MI-2 - Incidents are mitigated'}
        , { id:'RS.MI-3', datapoint:'RS.MI-3 - Newly identified vulnerabilities are mitigated or documented as accepted risks'}
        , { id:'RS.IM-1', datapoint:'RS.IM-1 - Response plans incorporate lessons learned'}
        , { id:'RS.IM-2', datapoint:'RS.IM-2 - Response strategies are updated'}
        , { id:'RC.RP-1', datapoint:'RC.RP-1 - Recovery plan is executed during or after an event'}
        , { id:'RC.IM-1', datapoint:'RC.IM-1 - Recovery plans incorporate lessons learned'}
        , { id:'RC.IM-2', datapoint:'RC.IM-2 - Recovery strategies are updated'}
        , { id:'RC.CO-1', datapoint:'RC.CO-1 - Public relations are managed'}
        , { id:'RC.CO-2', datapoint:'RC.CO-2 - Reputation after an event is repaired'}
        , { id:'RC.CO-3', datapoint:'RC.CO-3 - Recovery activities are communicated to internal stakeholders and executive and management teams'}
    ])
}

export { GetFramework }
