import { formatMs } from '@material-ui/core';
import { SaveButton, Toolbar, useNotify, useRedirect, useCreate } from 'react-admin';
import { useFormContext, useForm } from 'react-hook-form';
import React, { useState, } from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';

import { GetFramework } from '../dataproviders/frameworks.js';



/*
 (data) => { 
                    console.log(data)
                    notify('Analysis completed successfully.'); 
                    reset();
                }
                */

const AnalysisToolbar = (props) => {
    const [ create ] = useCreate();
    const { getValues, reset } = useFormContext();
    const { control, formState } = useForm();
    const redirect = useRedirect();
    const notify = useNotify();
    const [ issaving, setSavingState] = useState(false);
    
    const handleClick = e => {
        //e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const { ...data  } = getValues();

        if(data.docsetid || data.docid){

            if(props.onsubmit_callback){
                
                if(data.dataPointSource!='FREEFORM')	
                {
                    GetFramework(data.dataPointSource).then((framework)=>{
                        data.question = framework.data.filter((f)=>f.id==data.id)[0].datapoint
                        props.onsubmit_callback(data);
                    })
                    
                }else{
                    props.onsubmit_callback(data);
                }
            }
            setSavingState(true);
            create(
                'runanalysis',
                { data },
                { 
                    onSuccess: (rspdata) => { 
                        if(props.onsuccess_callback){
                            props.onsuccess_callback(rspdata,data);
                        }
                        notify('Analysis completed successfully.'); 
                        reset({docsetid:data.docsetid,docid:data.docid});
                        setSavingState(false);
                    }
                    ,onError: (err) => { 
                        if(props.onerror_callback){
                            props.onerror_callback(err, data);
                        }
                        notify('We encountered an error completing the analysis.'); 
                        reset({docsetid:data.docsetid,docid:data.docid});
                        setSavingState(false);
                    }
                }
            );
        }
    };

    const PostSaveButton = props => {
        const notify = useNotify();
        const redirect = useRedirect();
        const reset = useFormContext();
       
        return <SaveButton {...props} 
                    label="Analyze"
                    type="button"
                    variant="text"
                    disabled={props.disabled || false}
                    icon={<EngineeringIcon />}
                    />;
    };

    return  <Toolbar>
                <PostSaveButton type="button" onClick={handleClick} disabled={issaving} />
            </Toolbar>;
};

export {AnalysisToolbar };