import React, {useCallback,useEffect,useState } from "react";
import { useFormState, useForm } from 'react-hook-form';
import { SaveButton, Toolbar } from 'react-admin';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';


  const ChangeMfaToolbar = (props) => {
    const formState = useFormState();
    const form = useForm();
    const [savebuttondisabled,setSaveButtonDisabled]= useState(true);
    
    useEffect(()=>{
        
        setSaveButtonDisabled(props.saveButtonDisabled);

          
    },[props.saveButtonDisabled]);

    const handleCustomSubmit = useCallback(() => {
        
            if(props.onSubmit){
                props.onSubmit();
            }
        
      }, [formState.defaultValues,formState.isValid]);
  
    const isNilOrEmpty = (record)=>{
        return (!record||undefined);
    }

    return (
        <Toolbar>
            <Button
              id="btnchangemfa"
              label={props.label||"Save"}
              onClick={handleCustomSubmit}
              variant="text"
              disabled={savebuttondisabled}
              startIcon={<SaveIcon />}
            >{`${props.label||"Save"}`}</Button>
        </Toolbar>
    );
  };

export default ChangeMfaToolbar;