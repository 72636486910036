import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
  } from "react";
  import {
    TextInput,
    ImageInput,
    ImageField,
    SimpleForm,
    required,
    useDataProvider,
    useNotify,
    SaveContextProvider,
    useGetIdentity
  } from "react-admin";
  
  

  import { GetPortalSession } from '../dataproviders/userinfo'

  const ProfileContext = createContext();
  
  export const ProfileProvider = ({ children }) => {
    const [profileVersion, setProfileVersion] = useState(0);
    const context = useMemo(
      () => ({
        profileVersion,
        refreshProfile: () =>
          setProfileVersion((currentVersion) => currentVersion + 1)
      }),
      [profileVersion]
    );
  
    return (
      <ProfileContext.Provider value={context}>
        {children}
      </ProfileContext.Provider>
    );
  };
  
  export const useProfile = () => useContext(ProfileContext);
  export const ProfileEdit = ({staticContext, ...props})=>{
    const [portalsessionurl, setPortalSessionUrl] = useState();

    

    useEffect(()=>{
            
        if(!portalsessionurl){
            GetPortalSession().then((data)=>{
                setPortalSessionUrl(data.access_url);
                //window.open(data.access_url,"subscriptionsportal",'location=no,menubar=no,resizable=no,status=no,titlebar=no,toolbar=no');
                document.location=data.access_url;
            });
            
        }
    });
       
    /*<iframe src={portalsessionurl} frameborder="0" width="100%" height="100%" />*/
    return (
      <div>
        <p>Subscription management is performed using Stripe.  A new window will load for your to manage subscription details.</p>
        <p>Check your pop-up blocker if you did not see a new window created to access your subscription information.</p>
      </div>
        
    );


  }
  export const ProfileEdit1 = ({ staticContext, ...props }) => {
    console.log("ProfileEdit");
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState();
    const { refreshProfile } = useProfile();
  
    const { loaded, identity } = useGetIdentity();
  
    const handleSave = useCallback(
      (values) => {
        setSaving(true);
        dataProvider.updateUserProfile(
          { data: values },
          {
            onSuccess: ({ data }) => {
              setSaving(false);
              notify("Your profile has been updated", "info", {
                _: "Your profile has been updated"
              });
              refreshProfile();
            },
            onFailure: () => {
              setSaving(false);
              notify(
                "A technical error occured while updating your profile. Please try later.",
                "warning",
                {
                  _:
                    "A technical error occured while updating your profile. Please try later."
                }
              );
            }
          }
        );
      },
      [dataProvider, notify, refreshProfile]
    );
  
    const saveContext = useMemo(
      () => ({
        save: handleSave,
        saving
      }),
      [saving, handleSave]
    );
  
    return (
        <iframe src="https://d6cyber-test.chargebee.com/portal/v2/authenticate?token=RYDpfCqcuh9UFem84e0XxpdPBIrOo5pgi" width="100%" height="100%" />
      
    );
  };
  