import { API, Amplify} from 'aws-amplify';


const apiName = 'default';


const GetUsage = async()=>{
    return API.get(apiName, '/app/profile/usage');        
}

export { GetUsage }
