import React, {useState} from "react";
import {TextInput,minLength,SimpleForm,required,useNotify} from "react-admin";
import { Auth } from 'aws-amplify';
import Container from '@mui/material/Container';
import ChangePasswordToolbar from "./changepasswordtoolbar";



const ChangePassword = (props)=>{
    const [issaving,setIsSaving]=useState(false);
    const [formvalues, setFormValues] = useState({});
    const notify = useNotify();
    
    const currentuser = props.CurrentUser;

    
    const textfieldchange=(event)=>{
        formvalues[event.target.id]=event.target.value;
        setFormValues(formvalues);
      }

    const textfieldvalue=(id)=>{
    return formvalues[id] || '';
    }

    const onSubmit = () => {
        
        setIsSaving(true);
        Auth.changePassword(currentuser, formvalues['crrpwd'], formvalues['newpwd1'])
        .then((data)=>{
            notify("Password changed successfully.");
        })
        .catch((error)=>{
            notify(`Error changing password.  Check your current Password and try again.  ${error.name}`);
        })
        .finally(()=>{
            setFormValues({});
            setIsSaving(false);
        });
    
    };


return (
    <Container>
        <SimpleForm toolbar={<ChangePasswordToolbar onSubmit={onSubmit} label="Change Password" saving={issaving} />}>
        <div><TextInput id="crrpwd" source="crrpwd" label="Current Password" variant="outlined" type="password" onChange={textfieldchange} validate={[required(),minLength(8)]} /></div>
        <div><TextInput id="newpwd1" source="newpwd1" label="New Password" variant="outlined" type="password"  onChange={textfieldchange} validate={[required(),minLength(8),()=>(formvalues['newpwd1']==formvalues['crrpwd']?'New Password cannot match your current password.':undefined)]}/></div>
        <div><TextInput id="newpwd2" source="newpwd2" label="Confirm New Password" variant="outlined" type="password" onChange={textfieldchange} validate={[required(),minLength(8),()=>(formvalues['newpwd1']!=formvalues['newpwd2']?'New passwords do not match.':undefined)]}/></div>
        </SimpleForm>
    </Container>
)
}

export default ChangePassword;