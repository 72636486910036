import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SaveButton, Toolbar, useNotify, useUpdate } from 'react-admin';





const FileUploadToolbar = (props) => {
    const [ update ] = useUpdate();
    const { getValues, reset } = useFormContext();
    const notify = useNotify();
    const [issaving,setSavingState]=useState(false);
    
        const handleClick = e => {
            e.preventDefault(); // necessary to prevent default SaveButton submit logic
            const { ...data } = getValues();
            setSavingState(true);
            if(props.callback_events){
                props.callback_events('onuploadstart')
            }
            update(
                'fileupload',
                { data },
                { 
                    onSuccess: () => { 
                        setSavingState(false);
                        notify('Files uploaded successfully.'); 
                        if(props.callback_events){
                            props.callback_events('onuploadcomplete')
                        }
                        reset();
                    }
                    ,onError: () => { 
                        setSavingState(false);
                        notify('Error uploading files.'); 
                        if(props.callback_events){
                            props.callback_events('onuploadcomplete')
                        }
                        reset();
                    }
                }
            );
            
            setSavingState(false);
        };
    
        const PostSaveButton = props => {
            const notify = useNotify();
            const reset = useFormContext();
            
            return <SaveButton {...props} 
                        label="Upload"
                        type="button"
                        variant="text"
                        disabled={!(props.formvalidated=='true') || issaving}
                    />;
        };
        
    
        return <Toolbar>
                    <PostSaveButton type="button" onClick={handleClick} formvalidated={props.formvalidated} />
               </Toolbar>;
};

export { FileUploadToolbar };