import { objectLessAttributes } from '@aws-amplify/core';
import { API, Amplify} from 'aws-amplify';

import { MultipartFileUpload, FileUpload, GetDocuments, GetDocumentSets, CreateDocumentSet, GetDocumentUrl, DeleteDocuments} from './documents.js';
import { AnalyzeDocument } from './analysis.js';

import { GetFramework } from './frameworks.js';

let apiName = 'default';

let lastobservedkey;
let lastsort;
let isampconfigcomplete = false;

let delay = (time)=>{
	return new Promise(resolve => setTimeout(resolve, time));
}

let sort = (data,sort)=>{
	return data.sort((s1,s2)=> { 
		return (s1[sort.field]>s2[sort.field])
			?((sort.order=='DESC')?1:-1)
			:((sort.order=='DESC')?-1:1)
	})



}



export default {
    getList:    async( resource, params ) => {
		
		let listdata = {"data":[],"total":0}
        switch(resource.toLowerCase()){
            case "assessments":
                listdata.data.push({"id":"1","title":"my first assessment"});
				return new Promise(async (resolve,reject)=>{
					listdata.total = listdata.data.length;
					resolve(listdata);
				});
            break;
			case "documents":
				
				let documents = await GetDocuments(params);
				documents.data = (params.sort)?sort(documents.data,params.sort):documents.data;
				if(params.filter && params.filter.q){
					documents.data = documents.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					documents.total = documents.data.length;
				}
				return documents
			break;
			case "documentset":
				let documentsets = await GetDocumentSets();
				documentsets.data = (params.sort)?sort(documentsets.data,params.sort):documentsets.data;
				if(params.filter && params.filter.q){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.docsetname.indexOf(params.filter.q)>=0)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				if(params.filter && params.filter.status){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.status==params.filter.status)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				return documentsets;
			break;
			case "framework":
				let framework = await GetFramework((params.filter)?params.filter.framework:undefined);
				return framework;
			break;
			default:
				console.log(`requesting undefined resource ${resource}`);
				return listdata;
			break;

        }
        
		
    },
    getOne:     async(resource, params) => {
		
        return new Promise(async (resolve,reject)=>{
            
            resolve();
        });


	},
    getMany:    async(resource, params) => {

		let listdata = {"data":[]}
        switch(resource.toLowerCase()){
            case "documents":
				let documents = await GetDocuments(params);
				documents.data = (params.sort)?sort(documents.data,params.sort):documents.data;
				if(params.filter && params.filter.q){
					documents.data = documents.data.filter((f)=>{ return (f.filename.indexOf(params.filter.q)>=0)?true:false;});
					documents.total = documents.data.length;
				}

				return documents
			break;
			case "documentset":
				let documentsets = await GetDocumentSets();
				documentsets.data = (params.sort)?sort(documentsets.data,params.sort):documentsets.data;
				if(params.filter && params.filter.q){
					documentsets.data = documentsets.data.filter((f)=>{ return (f.docsetname.indexOf(params.filter.q)>=0)?true:false;});
					documentsets.total = documentsets.data.length;
				}
				return documentsets;
			break;
			case "framework":
				let framework = await GetFramework((params.filter)?params.filter.framework:undefined);
				return framework;
			break;
        }

		
	},
    getManyReference: (resource, params) => {
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    create:     async(resource, params) => {
		
		return new Promise(async(resolve,reject)=>{
			try{
			let listdata = {"data":[]}
			switch(resource.toLowerCase()){
				case "runanalysis":
						if(params.data.dataPointSource!='FREEFORM')	
						{
							let framework = await GetFramework(params.data.dataPointSource);
							params.data.question = framework.data.filter((f)=>f.id==params.data.id)[0].datapoint
						}
						resolve(await AnalyzeDocument(params));
					break;
				case "docset":
					resolve(await CreateDocumentSet(params));
					//resolve({"data":{id: params.data.docsetname.substring(0,3), name:params.data.docsetname}});
				break;
			}
			}catch(err){
				console.error(`error in create calling ${resource}`);
				reject(err);
			}
		});
	},
    update:     async(resource, params) => {
		
		return new Promise(async(resolve,reject)=>{
            
			try{
				switch(resource.toLowerCase()){
					case "fileupload":
						//resolve(await FileUpload(params));
						resolve(await MultipartFileUpload(params));
					break;
					
				}
			}catch(err){
				reject(err);
			}
			

			
		});

	},
    updateMany: (resource, params) => {
		
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    delete:     (resource, params) => {
		
		return new Promise((resolve,reject)=>{
			resolve();
		});
	},
    deleteMany: (resource, params) => {
		
		return new Promise(async(resolve,reject)=>{
            
			try{
				switch(resource.toLowerCase()){
					case "documents":
						resolve(await DeleteDocuments(params));
					break;
					
				}
			}catch(err){
				reject(err);
			}
			

			
		});
	},
}