import * as React from "react";
import { useState } from 'react';
import { required, SimpleForm, FileInput, FileField, useRefresh} from 'react-admin';

import LinearProgress from '@mui/material/LinearProgress';

import { FileUploadToolbar } from '../components/fileuploadtoolbar';
import { DocumentSetCombo } from '../components/createdocumentset';

export const FileUpload = (props) => {
    const [validinputs,setValidInputs]=useState(false);
    const [isuploading,setIsUploading]=useState(false);
    const [docsetselected,setdocsetselected]=useState(false);
    const [filesselected,setfilesselected]=useState(false);

    const refresh = useRefresh();
  
    const formvalidator=(formvalues)=>{
        
        const errors = {};
        if (!formvalues.docsetid) {
            errors.docsetid = 'Select a Document Set to associate the Upload(s) with.';
        }
        
        if(formvalues.attachments && formvalues.attachments.length==0){
            errors.attachments = "Select a file or file to upload."
        }

        if(formvalues.attachments && formvalues.attachments.length==0 && formvalues.attachments.length<=10){
            errors.attachments = "You may only upload up to 10 documents at once.  Please adjust your selection."
        }

        
        setValidInputs((Object.keys(errors).length==0));

        return {errors};
    }

    const callback_handler=(action,params)=>{
        
        switch(action.toLowerCase()){
            case 'onuploadstart':
                setIsUploading(true);
            break;
            case 'onuploadcomplete':
                setIsUploading(false);
                refresh();
            break;
        }
    }

    const checkdocset=(val)=>{
        setdocsetselected((val!=null));
    }
    const checkfiles=(vals)=>{
        setfilesselected((vals.length>0));
    }

    return (
    
        <div style={{minWidth:'600px'}}>
        <SimpleForm toolbar={<FileUploadToolbar callback_events={callback_handler} formvalidated={(docsetselected&&filesselected).toString()} />} validate={formvalidator}>
            <div className="flex-container">
                <DocumentSetCombo 
                    source="docsetid"
                    TextFieldProps={{placeholder: 'Find or Create a Document Set.'}}
                    allowcreate="true"
                    suggestionLimit={10}
                    validate={required()}
                    className="flex-container"
                    onChange={checkdocset}
                />
            </div>
            <div className="flex-container">
            <FileInput source="attachments" 
                        maxSize={10485760} 
                        accept="application/pdf" 
                        multiple 
                        validate={required()}
                        placeholder="Select one or more PDF files to upload.  Files must be less than 10MB in size."
                        onChange={checkfiles}>

                <FileField source="src" title="title" target="_blank"  />
            </FileInput>
            </div>
           
        </SimpleForm>
            <div style={{width:'100%'}} hidden={!(isuploading)}>
                <LinearProgress>upload files</LinearProgress>
            </div>
        </div>
    
    );
        
    
}


