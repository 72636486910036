import * as React from "react";
import { SelectInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useState } from "react";
import { SaveButton, TopToolbar, Toolbar, useNotify, useRedirect, useCreate, useRefresh,SimpleForm, TextInput, required, List, Show, Datagrid} from 'react-admin';
import { RadioButtonGroupInput } from 'react-admin';

import { Card, CardContent, CardHeader, Container } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormHelperText from '@mui/material/FormHelperText';


import { AnalysisToolbar } from '../components/analysistoolbar';
import { DocumentSetCombo, DocumentsCombo } from '../components/createdocumentset';
import { DataPointsCombo } from "../components/frameworkselector";
import { AnalysisOutput } from '../components/analysisoutput';


import '../css/analysis.css'
import '../css/documents.css'

export const RealtimeAnalysis = (props) => {

    const refresh=useRefresh();
    let response = []
    
    const [formdata, setFormData] = useState([]);
    const [rcontext, setRContext] = useState([]);
    const [rchoices, setRChoices] = useState([]);
    const [analysisout, setAnalysisOut] = useState([]);
    const [plug, doRefresh] = useState([]);
    const [targetsource, setTargetSource] = useState(1);
    const [seldocset,SetDocsetId]=useState('');
    const [dataPointSource, setDataPointSource]=useState('FREEFORM');
    const [analysisDataPoint, setAnalysisDataPoint]=useState('');
    
    
    const onsubmit_callback=(form) => {
        let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid,};
        analysisout.push(rsp);
        setAnalysisOut(analysisout);
        doRefresh(Math.random());
    }
       
    const onsuccess_callback= (rspdata,form) => { 

        let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid, "response":rspdata};
        analysisout[analysisout.length-1] = rsp;
        setAnalysisOut(analysisout);
        doRefresh(Math.random());
    }

    const onerror_callback=(err,form) =>{
        console.log('error encountered running analysis');
        console.log(err);
        let rsp = { "question": form.question, "context": form.context, "docsetid": form.docsetid, "docid":form.docid, "response": {"item":[]}, "error":{"message":JSON.stringify(err)}};
        analysisout[analysisout.length-1] = rsp;
        setAnalysisOut(analysisout);
        doRefresh(Math.random());

    }

    const analysisDataPointChange=(event)=>{
        setAnalysisDataPoint(event);
    }

    const analysisSourceChange=(event)=>{
        if(event.target.value){
            setDataPointSource(event.target.value);
        }
    }

    const target_onChange=()=>{
        if(targetsource==1){
            setTargetSource(2);
        }else{
            setTargetSource(1);
        }
    }

    const changedocset=(event)=>{
        SetDocsetId(event);
    }

    const formvalidator=(values)=>{
        console.log('do form')
        console.log(values);
    }

      

    return (
    
    <Container>
        
        <Container  className="ResponseContainer" disableGutters>
            <AnalysisOutput 
                dataset={analysisout} 
                disableGutters maxWidth={true} 
                delete_onclick={(item)=>{
                    analysisout.splice(analysisout.indexOf(item),1); 
                    setAnalysisOut(analysisout);
                    doRefresh(Math.random());
                    //setRChoices(item);
                }}
                
            />
        </Container>
        <Container className="QuestionContainer">
        <SimpleForm toolbar={<AnalysisToolbar 
                    onsuccess_callback={onsuccess_callback} 
                    onsubmit_callback={onsubmit_callback} 
                    onerror_callback={onerror_callback} 
                    validate={formvalidator} />}>
                        
                        {/*<div className="flex-container">
                                        <div className="flex-child">
                                            
                                        </div>
                                        <div className="flex-child-second">
                                        
                                        </div>
                        </div>*/}
                        <div className="flex-container">
                            
                        </div>
                        <div className="flex-container">
                            <div className="flex-child-first">
                                <SelectInput source="dataPointSource" choices={[
                                    { id: 'FREEFORM', name: 'Free Form' },
                                    { id: 'CSC8', name:'CSC 8.0'},
                                    { id: 'FFIECCAT', name: 'FFIECCAT' },
                                    { id: 'NISTCSF', name: 'NIST CSF' },
                                    
                                    ]}
                                onChange={analysisSourceChange} 
                                defaultValue={dataPointSource}
                                />
                            </div>
                            <div className="flex-child-second">
                               {(dataPointSource=="FREEFORM")
                                ?<TextInput source="question" fullWidth label="Data Point" id="txtdatapoint" />
                                :<DataPointsCombo id="ddldatapoint" TextFieldProps={{placeholder: 'Select a Framework Data Point'}} framework={dataPointSource}  onChange={analysisDataPointChange}  />
                                }
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="flex-child">
                            { /*(targetsource==1)?<DocumentSetCombo TextFieldProps={{placeholder: 'Select a document set'}} validate={[required()]} />:<DocumentsCombo TextFieldProps={{placeholder: 'Select a document'}} validate={[required()]} /> */}
                                <DocumentSetCombo TextFieldProps={{placeholder: 'Must have Document in COMPLETE Status.'}} onChange={changedocset} id="ddldocset" validate={required()} status="COMPLETE" />
                            </div>
                            <div className="flex-child-second">
                                <DocumentsCombo TextFieldProps={{placeholder: 'Only Documents in COMPLETE Status are available for analysis.'}} selecteddocset={seldocset} id="ddldoc" status="COMPLETE" />
                            </div>
                        </div>
                       {/*  <div className="flex-container">
                            <RadioGroup name="sourcetype" defaultValue={targetsource} value={targetsource} onChange={target_onChange} row={true} >
                                <FormControlLabel value="1" control={<Radio />} label="Document Set" />
                                <FormControlLabel value="2" control={<Radio />} label="Document" />
                            </RadioGroup>
                        </div>
                        */}
                        {/*<div className="flex-child-second">
                                <TextInput source="context" validate={[required()]} fullWidth />
                                </div>*/}
                       
        </SimpleForm>                        
        </Container>
        
    </Container>
    )
        
}


export const RealtimeAnalysisList = (props) => {
    return (
        <List {...props} empty={<RealtimeAnalysis />}>
            <Datagrid><span /></Datagrid>  
        </List> 
    )
}