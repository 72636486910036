import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
  } from "react";
  import {
    TextInput,
    ImageInput,
    ImageField,
    minLength,
    SimpleForm,
    required,
    useDataProvider,
    useNotify,
    SaveContextProvider,
    useGetIdentity,
    List,
    Title
  } from "react-admin";

  import { useFormContext } from 'react-hook-form';
  import { SaveButton, Toolbar } from 'react-admin';
  import { useLocation } from "react-router-dom";
  

  

  import { Auth } from 'aws-amplify';

  import Button from '@mui/material/Button';
  import Container from '@mui/material/Container';
  import LinearProgress from '@mui/material/LinearProgress';
  import Paper from '@mui/material/Paper';
  import Stack from '@mui/material/Stack';
  import TextField from '@mui/material/TextField';
  import { styled } from '@mui/material/styles';

  import Accordion from '@mui/material/Accordion';
  import AccordionSummary from '@mui/material/AccordionSummary';
  import AccordionDetails from '@mui/material/AccordionDetails';
  import Typography from '@mui/material/Typography';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  

  import QRCodeSVG from 'qrcode.react';

  import Item from '../components/item';
  import ChangePassword from '../components/changepassword';
  import MfaSetup from "../components/changemfa";
  import SubscriptionDetail from "../components/subscriptiondetail";
  
  import '../css/preferences.css'
  

  export const Preferences = ({staticContext, ...props})=>{
    const [qrcodeurl, setQrCodeUrl] = useState();
    const [verifyotp, setVerifyOtp] = useState('');
    const [preferredmfa, setPreferredMFA] = useState('');
    const [currentuser, setCurrentUser] = useState();
    const notify = useNotify();
    const [formvalues, setFormValues] = useState({});
    const search = useLocation().search;

    useEffect(()=>{
        
      const loader =  ()=>{
        
        return new Promise((resolve,reject)=>{
          Auth.currentAuthenticatedUser()
          .then((user)=>{
            resolve(user);
          })
          .catch((err)=>{
            reject(err);
          });
        })
      }

     
      loader().then((user)=>{
        setCurrentUser(user);
      })

      const uparams = new URLSearchParams(search)
      let toclose = uparams.get('close');
      if(toclose=='true'){
        console.log('close window');
        if(window.parent){
          window.parent.close();
        }
      }



      
        
    },[]);


   

    
       
    
    return (currentuser)?(
    
      <React.Fragment>
        <Title>User Preferences</Title>
        <Stack spacing={2}>
          <Item><Container>Logged in as: {`${currentuser.attributes.email}`}</Container></Item>
          <Item>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-pwd"
                id="panel-pwd-header"
              >
                <Typography>Change Password</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChangePassword CurrentUser={currentuser} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-mfa"
                id="panel-mfa-header"
              >
                <Typography>Multi-Factor Authentication (MFA)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MfaSetup CurrentUser={currentuser} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-subscription"
                id="panel-subscription-header"
              >
                <Typography>Subscription Management</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SubscriptionDetail CurrentUser={currentuser} />
              </AccordionDetails>
            </Accordion>
          </Item>
        </Stack>
      </React.Fragment>
        
    ):<LinearProgress>loading</LinearProgress>


  }
 