
import { useAuthenticator, View, Image, Text, Heading, Button, useTheme} from '@aws-amplify/ui-react';


import { ThemeProvider, Theme } from '@aws-amplify/ui-react';


const AuthenticatorTheme =()=>{
  const { tokens } = useTheme();
  return(

      {
        name: 'AscendAI',
        tokens: {
          colors: {
            background: {
              primary: {
                value: tokens.colors.yellow['90'].value,
              },
              secondary: {
                value: tokens.colors.yellow['100'].value,
              },
            },
            font: {
              interactive: {
                value: tokens.colors.white.value,
              },
            },
            brand: {
              primary: {
                '10': tokens.colors.yellow['100'],
                '80': tokens.colors.yellow['40'],
                '90': tokens.colors.yellow['20'],
                '100': tokens.colors.yellow['10'],
              },
            },
          },
          components: {
            tabs: {
              item: {
                _focus: {
                  color: {
                    value: tokens.colors.white.value,
                  },
                },
                _hover: {
                  color: {
                    value: tokens.colors.yellow['80'].value,
                  },
                },
                _active: {
                  color: {
                    value: tokens.colors.white.value,
                  },
                },
              },
            },
          },
        },
      }

    )
} 
 

  
  const AuthenticatorComponents = {
    Header() {
      const { tokens } = useTheme();
      
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="AscendAI"
            src="/AscendAI-WhiteBG_Low.png"
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; AscendAI.io - All Rights Reserved
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
          
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text />;
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text />;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text />;
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text />;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text />;
      },
    },
  };



export { AuthenticatorComponents, AuthenticatorTheme };