import { Card, CardContent, CardHeader, Container, List } from '@material-ui/core';
import { ListItem, ListItemText, IconButton, Checkbox, FormControlLabel} from '@mui/material'    
import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import {useNotify} from 'react-admin';
import CommentIcon from '@mui/icons-material/QuestionAnswer';

import QuickreplyIcon from '@mui/icons-material/Quickreply';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import AdjustIcon from '@mui/icons-material/Adjust';

import PsychologyAltIconOutlined from '@mui/icons-material/PsychologyAltOutlined';
import QuestionAnswerIconOutlined from '@mui/icons-material/QuestionAnswerOutlined';
import LiveHelpIconOutlined from '@mui/icons-material/LiveHelpOutlined';
import QuizIconOutlined from '@mui/icons-material/LiveHelpOutlined';
import HelpCenterIconOutlined from '@mui/icons-material/HelpCenterOutlined';
import DeleteSweepIconOutlined from '@mui/icons-material/DeleteSweepOutlined';
import FileDownloadIconOutlined from '@mui/icons-material/FileDownloadOutlined';
import LibraryAddIconOutlined from '@mui/icons-material/LibraryAddOutlined';
import CopyAllIconOutlined from '@mui/icons-material/CopyAllOutlined';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';


import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';



import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';


import { GetDocumentSets, GetDocuments } from '../dataproviders/documents'
import { min } from 'lodash';


const PromptOutput = (props)=>{
    let item = props.dataset;
    const notify = useNotify();
    const [docsets, setDocSets] = useState();
    const [docs, setDocs] = useState();
    const divRef = useRef();
    
        const deleteitem = (evt)=>{
            let d = item;
            props.delete_onclick(d);
            notify("Analysis deleted.");
        }

        const copyitem = (evt)=>{
            
            let dsetname = docsets.filter((f)=>f.id==item.docsetid)[0].docsetname;
            let rsp = `"${dsetname}"\t"${item.question.replace('"','""')}"\t${constructresponseforcopy((item.response.choices[0].text || item.response.choices[0].message.content),item.response.analysisid)}`;
            
            textToClipboard(rsp)
            .then((m)=>{
                notify("Copied to clipboard successfully!");
            })
            .catch((e)=>{
                notify("Unable to write to clipboard.");
            })
            

            

        }
        
    
        useEffect(()=>{
            
            if(!docsets){
                
             let docset = GetDocumentSets()
                .then((data)=>{
                    setDocSets(data.data);
                })
            }

            if(!docs){
                GetDocuments().then((data)=>{
                    setDocs(data.data);
                })
            }

            if (divRef.current) {
        
                divRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
            }
        },[]);

        let dset = (docsets) ? docsets.filter((d)=>{ return (d.id==item.docsetid||d.id==item.id)})[0]:undefined; //docsetname
        let doc = (docs) ? docs.filter((d)=>{ return d.id==item.docid})[0]:undefined; //docsetname
        return (
           

                <div className="promptcontainer">
                    <div className="messagecontainer">
                        <div className="leftmargincontainer">
                            <div className="relative flex">
                                <span className="pageSpan">
                                    <HelpCenterIconOutlined color="secondary" fontSize="large" />
                                </span>
                            </div>
                        </div>
                        <div className="outputcontainer">
                            <div className="flex flex-grow flex-col gap-3">
                                <div className="prompttext docset"><b>Target:</b>{`${(dset && dset.docsetname)||(doc && doc.filename)||''}`}</div>
                                {/*<div className="prompttext context"><b>Context:</b><br />{`${item.context}`}</div>*/}
                                <div className="prompttext question"><b>Data Point:</b>{`${item.question}`}</div>
                            </div>
                            <div className="promptactioncontainer">
                                <button className="promptactionbutton"  aria-label="Copy Analysis" title="Copy Analysis" onClick={copyitem}>
                                    <CopyAllIconOutlined color="action"/>
                                </button>
                                <button className="promptactionbutton" aria-label="Delete" title="Delete" onClick={deleteitem}>
                                    <DeleteSweepIconOutlined color="action"/>
                                </button>
                            </div>
                            <div className="flex justify-between"></div>
                       
                    
                            {(item.response)
                                ?(item.error)
                                    ? <Alert severity="error">Error running analysis.  Please try again.</Alert>
                                    : <div ref={divRef} style={{height:'0px'}} />
                                :  <div className="promptcontainer"><LinearProgress /><div ref={divRef} style={{height:'325px'}} /></div> 
                            }
                         </div>
                    </div>
                </div>                                
            
        )
    

}

const constructresponseforcopy = (response,analysisid)=>{
    let context='';
    for(let el of document.forms[analysisid].elements){
        if(el.checked)
        {
            context += `${el.parentElement.nextSibling.innerText}\r\n\r\n`
        }
    }
    
    let answer = getContentFromJson(response).match(/^Yes.+/i)?'Yes':'No';
    response=getContentFromJson(response).replace(/\r\n/g,'').replace('"','""');
    context = context.replace(/\r\n\r\n$/,'');
   
    return `"${answer}"\t"${response.replace(/^\s+/,'')}"\t"${context}"`
}

const getContentFromJson=(jsonstr)=>{
    let retval='';
    try{
        let content = JSON.parse(jsonstr);
        if((content.summary.match(/^\s?[Yes|No]/))){
            retval=`${content.summary.trim()}`;
        }else{
            retval=`${content.response}.  ${content.summary.trim()}`;
        }
    }catch(err){
        console.log('error parsing json in response');
        console.log(err);
        
        retval=jsonstr;
    }
    return retval;
}

const textToClipboard=(textvalue)=>{
    return new Promise((resolve,reject)=>{
        var data = [new ClipboardItem({ "text/plain": new Blob([textvalue], { type: "text/plain" }) })];
        navigator.clipboard.write(data).then(function() {
        //notify("Copied to clipboard successfully!");
        resolve("Copied to clipboard successfully!")
        }, function() {
        //notify("Unable to write to clipboard.");
        reject("Unable to write to clipboard.")
        });
    })
    

}

const ResponseOutput = (props)=>{
    let item = props.dataset;
    let itemrecords=0;

    const notify = useNotify();
    const [togglestate,setToggleState]= useState({});

    try{
        itemrecords=item.response.choices.length;
    }catch(err){
        //no item records
    }

    const spanRef = useRef();
    useEffect(() => {
        if (spanRef.current) {
            
            spanRef.current.scrollIntoView(
            {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            })

            
        }
    
    })

    const togglecheck = (event)=>{
        if(!togglestate[event.target.id]){
            togglestate[event.target.id] = event.target.defaultChecked;
        }
        togglestate[event.target.id]=event.target.checked;
        setToggleState(togglestate)
        
    }
   
    const responseToClipBoard=(response,analysisid)=>{
        
        textToClipboard(constructresponseforcopy(response,analysisid))
        .then((m)=>{
            notify("Copied to clipboard successfully!");
        })
        .catch((e)=>{
            notify("Unable to write to clipboard.");
        })

    }
   
    const sortHashKeysByProperty=(obj,sortkey,sortdirection)=>{
        let retobj={}
        let keysort=[];
        let direction = (sortdirection && sortdirection.toLowerCase()=='desc')?1:-1
        for(let k of Object.keys(obj)){
            keysort.push({k:k,v:obj[k][sortkey]});
        }
        for(let sk of keysort.sort((o1,o2)=>(o2[sortkey]>o1[sortkey])?direction:direction*1)){
            retobj[sk.k]=obj[sk.k];
        }
        return retobj;
    };

    

    if(itemrecords>0){
        const analysisid = item.response.analysisid;
        let support = item.response.context.sort((s1,s2)=> s1.title.localeCompare(s2.title) || parseInt(s1.page) - parseInt(s2.page) );
        let lastdocid=''
        let relevancesummary={};
        for(let s of support){
            if(lastdocid!=s.docid){
                relevancesummary[s.docid]={};
                relevancesummary[s.docid].title=s.title;
                relevancesummary[s.docid].filename=s.filename;
                relevancesummary[s.docid].similars=[];
                relevancesummary[s.docid].context=[];
            }
            relevancesummary[s.docid].similars.push(s.similarvalue);
            relevancesummary[s.docid].context.push(s);
            lastdocid=s.docid;
        }
        let averages = [];
        for(let did of Object.keys(relevancesummary))
        {
            relevancesummary[did].average = relevancesummary[did].similars.reduce((p,c,_,a) => p + c/a.length,0);
            averages.push(relevancesummary[did].average);
        }

        const maxaverage = Math.max(...averages);
        const minaverage = Math.min(...averages);
        const averagees = maxaverage - minaverage;
        const bandsize = averagees/5;
        const averagerelevance = averages.reduce((p,c,_,a) => p + c/a.length,0)
        const minrange = ((minaverage * 0.05)<=0)?0:minaverage*.05;
        const maxrange = ((maxaverage*1.05)>=1)?1:maxaverage*1.05;
        const range = maxrange-minrange;
        const bandincrement = range/5;
        
        relevancesummary = sortHashKeysByProperty(relevancesummary,'average');
        
        return (
            item.response.choices.map((choice)=>(


                <div className="promptcontainer">
                    <div className="messagecontainer">
                        <div className="leftmargincontainer">
                            <div className="leftmarginiconcontainer" >
                                <QuestionAnswerIconOutlined  />
                            </div>
                        </div>
                        <div className="outputcontainer">
                            <div className="flex flex-grow flex-col gap-3">
                                <div className="outputresponsecontainer response">
                                    <div className="outputmarkdowncontainer">
                                    <b>Confidence</b>
                                    {/* */}
                                    <Slider
                                        aria-label="Confidence"
                                        defaultValue={Math.round(choice.confidence*100)}
                                        value={Math.round(choice.confidence*100)}
                                        valueLabelFormat={(value)=>{return `${value}%`}}
                                        step={20}
                                        min={0}
                                        max={100}
                                        valueLabelDisplay="on"
                                        marks={[
                                            {
                                              value: 20,
                                              label: '20%',
                                            },
                                            {
                                              value: 40,
                                              label: '40%',
                                            },
                                            {
                                              value: 60,
                                              label: '60%',
                                            },
                                            {
                                                value: 80,
                                                label: '80%',
                                            }
                                          ]}
                                        disabled={true}
                                        />
                                    <div>
                                        {`${choice.text || getContentFromJson(choice.message.content) }`}
                                    </div> 
                                    </div>
                                </div>
                                <div className="outputresponsecontainer context">
                                    <div className="outputmarkdowncontainer">
                                        <b>Supporting Context</b>
                                        <form name="" id={`${analysisid}`}>
                                        {
                                            Object.keys(relevancesummary).map((rs)=>(
                                                <div>
                                                    <div className="support_item_heading_container">
                                                        <div className="support_item_heading_child">
                                                            <Link to={`/documents/view/${rs}`} target={`_view_${rs}`} className="doclink">{`${relevancesummary[rs].title}`}</Link>
                                                        </div>
                                                         <div className="support_item_heading_child">
                                                            {/*<Rating name="read-only" value={(relevancesummary[rs].average-minrange)/bandincrement} precision={0.25} readOnly />*/}

                                                        </div>
                                                    </div>
                                                    { (Object.keys(relevancesummary).indexOf(rs)==0)?<span ref={spanRef} style={{height:'0px'}}>&nbsp;</span>:<span /> }
                                                    <ul className="support_item_list">
                                                        
                                                        {
                                                            relevancesummary[rs].context.map((rc)=>(
                                                                <li className="support_item">
                                                                     <div className="support_item_heading_container">
                                                                        <div className="support_item_heading_child">
                                                                            {/*(parseFloat(rc.similarvalue)>=relevancesummary[rs].average)&&checkedstate*/}
                                                                            <FormControlLabel control={<Checkbox defaultChecked={(parseFloat(rc.similarvalue)>=relevancesummary[rs].average*-1)} onChange={togglecheck} id={`${item.response.analysisid}_chk_${Object.keys(relevancesummary).indexOf(rs)}_${relevancesummary[rs].context.indexOf(rc)}`} />} label={`${rc.sentence} - ${relevancesummary[rs].title}, page: ${rc.page}`} />
                                                                        </div>
                                                                        <div className="support_item_heading_child">
                                                                            <Rating name="read-only" value={rc.similarvalue*5} precision={0.25} readOnly />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    
                                                    </ul>
                                                </div>
                                                
                                                
                                            ))
                                            
                                        }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div className="promptactioncontainer">
                                    <button className="promptactionbutton" aria-label="Copy Selected Response" title="Copy Selected Response" onClick={()=>{responseToClipBoard((choice.text ||choice.message.content),analysisid)}}>
                                        <CopyAllIconOutlined color="action" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            ))
        )
    }else{
        return (<span />)
    }
}
const AnalysisOutput = (props)=>{
    let data = props.dataset;
    

    const divRef = useRef();

    useEffect(() => {
        if (divRef.current) {
          
          /*divRef.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest'
            })*/
        }
      })

    if(data.length>0){
        
        return (data.map((item)=>(
            
                <Container>
                    <PromptOutput dataset={item} delete_onclick={props.delete_onclick} disableGutters maxWidth={true}/>
                    <ResponseOutput dataset={item} disableGutters maxWidth={true}/>
                    {(data.indexOf(item)==(data.length-1)) && <div ref={divRef} style={{height:'275px'}} /> }
                </Container>
                 
            )
        ))
    }else{
        return (
        <div className="question_noout_panel">
            <div className="question_noout_container">
               
                <div className="question_noout_child">
                    <ul className="infodisplay">
                        <li><PsychologyAltIcon color="info" sx={{fontSize:'72px'}} /></li>
                        <li className="heading">1.  Specify Data Point</li>
                        <li>Specify the data point or question to analyze for your Target.</li>
                    </ul>
                </div>
                <div className="question_noout_child">
                    <ul className="infodisplay">
                        <li><AdjustIcon color="info" sx={{fontSize:'72px'}}/></li>
                        <li className="heading">2.  Set a Target</li>
                        <li>Select a Document Set or Document as your analysis Target.</li>
                        
                    </ul>
                </div>
                <div className="question_noout_child">
                    <ul className="infodisplay">
                        <li><QuickreplyIcon color="info" sx={{fontSize:'72px'}} /></li>
                        <li className="heading">3.  Launch Analysis</li>
                        <li>
                            Launch the analysis.  Responses are provided within seconds.
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
        
    }
}



export { AnalysisOutput }